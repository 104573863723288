<ng-container *ngIf="meetingService.meetingSchedules$ | async as schedules">
  <div *ngIf="schedules.length" class="card-wrp" data-cy="meeting-schedules-card">
    <ninety-card *ngrxLet="vm$ as vm">
      <ninety-card-header header>
        <ninety-card-title-with-count title="Upcoming {{ vm.language.meeting.items }}"></ninety-card-title-with-count>
      </ninety-card-header>

      <table mat-table [dataSource]="schedules" class="meeting-schedules">
        <ng-container matColumnDef="frontSpacer">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef class="front-spacer"></td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let schedule">
            <div class="date-wrp">
              {{ schedule.scheduledDate | upcomingDate }}

              <ng-container *ngIf="schedule.cadence !== Cadence.none">
                <terra-icon class="muted" icon="clock" size="16" placement="bottom" [tp]="tpl" />
                <ng-template #tpl let-hide>
                  <div class="text-center">
                    Repeating
                    <div class="text-invert-light">{{ schedule.cadence | titlecase }}</div>
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="time">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Time</th>
          <td mat-cell *matCellDef="let schedule">
            {{ schedule.scheduledDate | date : 'h:mma' | lowercase }}
          </td>
        </ng-container>

        <ng-container matColumnDef="agenda">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Agenda</th>
          <td mat-cell *matCellDef="let schedule">
            {{ schedule.agendaName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="presenter">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Presenter</th>
          <td mat-cell *matCellDef="let schedule">
            <terra-avatar
              [initials]="schedule.presenterUser | userInitials"
              [src]="schedule.presenterUser.metadata?.picture?.url"
              [inactive]="!schedule.presenterUser.active"
              [ninetyContactCardHover]="schedule.presenterId"
              [interactive]="true" />
          </td>
        </ng-container>

        <ng-container matColumnDef="launch">
          <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let schedule; let i = index">
            <ninety-button
              primary
              filled
              (click)="createMeeting(schedule)"
              class="long-button"
              id="launch-meeting-btn"
              data-cy="launch-meeting-btn"
              [disabled]="schedule.scheduledDate | disableLaunchSchedule : i : vm.meetingInProgress">
              <terra-icon icon="monitor-play" size="20" />
              <span>Launch {{ vm.language.meeting.item }}</span>
            </ninety-button>
          </td>
        </ng-container>

        <ng-container matColumnDef="contextMenu">
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let schedule"
            class="context-menu-cell"
            (click)="onClickOpenContextMenu($event, schedule)">
            <terra-icon class="context-menu-icon" icon="more" />
          </td>
        </ng-container>

        <ng-container matColumnDef="endSpacer">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef class="end-spacer"></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr
          mat-row
          *matRowDef="let schedule; columns: columns"
          id="schedule-row-{{ schedule._id }}"
          (contextmenu)="onClickOpenContextMenu($event, schedule)"></tr>
      </table>

      <ninety-meeting-schedules-context-menu (delete)="onDelete($event)" />
    </ninety-card>
  </div>
</ng-container>
