import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { ErrorTagService } from '@ninety/ui/legacy/core/analytics/google-analytics/services/error-tag.service';
import { NavigationTagService } from '@ninety/ui/legacy/core/analytics/google-analytics/services/navigation-tag.service';
import { SegmentService } from '@ninety/ui/legacy/core/analytics/segment/segment.service';

import { ClearbitService } from './clearbit/clearbit.service';
import { GainsightPXEffects } from './gainsight/_state/gainsight-px.effects';
import { GainsightPXService } from './gainsight/gainsight-px.service';
import { HubspotEffects } from './hubspot/_state/hubspot.effects';
import { HubspotService } from './hubspot/hubspot.service';
import { SegmentEffects } from './segment/segment.effects';

const providers = [
  ClearbitService,
  ErrorTagService,
  GainsightPXService,
  NavigationTagService,
  SegmentService,
  HubspotService,
];

const effects = [GainsightPXEffects, SegmentEffects, HubspotEffects];

@NgModule({
  imports: [EffectsModule.forFeature(effects)],
  providers: [...providers],
})
export class AnalyticsModule {}
