import { importProvidersFrom, NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { HeadlineResolver } from '@ninety/headlines/_shared/resolvers/headline.resolver';
import { BecomeAPartnerComponent } from '@ninety/layouts/components/become-a-partner/become-a-partner.component';
import { HelpfulComponent } from '@ninety/layouts/components/helpful/helpful.component';
import { HomeComponent } from '@ninety/layouts/components/home/home.component';
import { ActiveSubscriptionGuard } from '@ninety/ui/legacy/core/guards/active-subscription.guard';
import { AuthGuard } from '@ninety/ui/legacy/core/guards/auth.guard';
import { ChartsAuthGuard } from '@ninety/ui/legacy/core/guards/charts.auth.guard';
import { HelpfulPermissionsGuard } from '@ninety/ui/legacy/core/guards/helpful-permissions.guard';
import { MasteryGuard } from '@ninety/ui/legacy/core/guards/mastery.guard';
import { PartnerHubAccessGuard } from '@ninety/ui/legacy/core/guards/partner-hub-access.guard';
import { PartnersPermissionsGuard } from '@ninety/ui/legacy/core/guards/partners-permissions.guard';
import { PrivateAccountabilityChartGuard } from '@ninety/ui/legacy/core/guards/private-accountability-chart.guard';
import { SwitchCompanyGuard } from '@ninety/ui/legacy/core/guards/switch-company.guard';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';

import { DetailViewEffects } from './detail-view/_state/detail-view.effects';
import { HeadlinesModuleStateKey } from './headlines/_state';
import { CascadingMessagesStateEffects } from './headlines/_state/cascading-messages/cascading-messages-state.effects';
import { HeadlinesStateEffects } from './headlines/_state/headlines/headlines-state.effects';
import { HeadlinesModuleStateReducer, InitialHeadlinesModuleState } from './headlines/_state/headlines-state';
import { LearningModuleGuard } from './learning-modules/learning-module.guard';
import { onExitSurvey } from './pages/assessments/_guards/on-exit-survey.guard';
import { canActivateReportViewer } from './pages/report-viewer/_guards/report-viewer.guard';
import { RocksStateEffects } from './rocks-v2/_state/rocks-v2-state.effects';

const companyMatcher = (url: UrlSegment[]) => {
  if (url.length && url[0].path === 'company') {
    return { consumed: url };
  }
  return null;
};

const routes: Routes = [
  {
    matcher: companyMatcher,
    canActivate: [SwitchCompanyGuard],
    children: [],
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  { path: 'sign-up', redirectTo: 'login/sign-up', pathMatch: 'full' },
  { path: 'sign-up/:partner', redirectTo: 'login/sign-up/:partner', pathMatch: 'full' },
  { path: 'accept-invite', redirectTo: 'login/accept-invite', pathMatch: 'full' },
  { path: 'registration', redirectTo: 'login/sign-up', pathMatch: 'full' },
  {
    path: 'create-company',
    loadChildren: () => import('./create-company/create-company.module').then(m => m.CreateCompanyModule),
    data: { title: 'Create Company' },
  },
  {
    path: 'survey/:userAssessmentId',
    redirectTo: 'assessment/:userAssessmentId',
  },
  {
    path: 'assessment/:userAssessmentId',
    loadComponent: () =>
      import('./pages/assessments/taker/assessment/assessment.component').then(c => c.AssessmentComponent),
    canDeactivate: [onExitSurvey],
  },
  {
    path: 'report-viewer/:reportName/:reportId/:reportAction',
    loadComponent: () => import('./pages/report-viewer/report-viewer.component').then(c => c.ReportViewerComponent),
    canActivate: [canActivateReportViewer],
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./my-ninety/my-ninety-routes').then(m => m.MyNinetyRoutes),
        canActivate: [AuthGuard],
      },
      {
        path: 'charts',
        loadChildren: () => import('./charts/charts.module').then(m => m.ChartsModule),
        canActivate: [ChartsAuthGuard],
      },
      {
        path: 'data-v2',
        loadChildren: () => import('./scorecard-v2/routes').then(m => m.ScorecardRoutes),
        canActivate: [AuthGuard, ActiveSubscriptionGuard],
      },
      {
        path: 'data',
        loadChildren: () => import('./scorecard/scorecard.module').then(m => m.ScorecardModule),
        canActivate: [AuthGuard, ActiveSubscriptionGuard],
      },
      {
        path: 'rocks',
        loadChildren: () => import('./rocks/rocks.module').then(m => m.RocksModule),
        canActivate: [AuthGuard, ActiveSubscriptionGuard],
      },
      {
        path: 'rocks-v3/:id',
        loadComponent: () => import('./rocks-v2/rocks-v2.component').then(c => c.RocksComponent),
        canActivate: [AuthGuard, ActiveSubscriptionGuard],
        providers: [importProvidersFrom(EffectsModule.forFeature([RocksStateEffects]))],
      },
      {
        path: 'milestones/:id',
        loadComponent: () => import('./rocks-v2/milestone/milestone.component').then(c => c.MilestoneComponent),
        canActivate: [AuthGuard, ActiveSubscriptionGuard],
        providers: [
          importProvidersFrom(EffectsModule.forFeature([DetailViewEffects])),
          importProvidersFrom(EffectsModule.forFeature([RocksStateEffects])),
        ],
      },
      {
        path: 'rocks-v3',
        loadChildren: () => import('./rocks-v3/rocks-v3.module').then(m => m.RocksV3Module),
        canActivate: [AuthGuard, ActiveSubscriptionGuard],
      },
      {
        path: 'todos',
        loadChildren: () => import('./todos/todos-app.module').then(m => m.TodosAppModule),
        canActivate: [AuthGuard, ActiveSubscriptionGuard],
      },
      {
        path: 'issues',
        loadChildren: () => import('./issues/issues.module').then(m => m.IssuesModule),
        canActivate: [AuthGuard, ActiveSubscriptionGuard],
      },
      {
        path: 'vision',
        loadChildren: () => import('./vto/vto.module').then(m => m.VtoModule),
        canActivate: [AuthGuard, ActiveSubscriptionGuard],
      },
      {
        path: 'meeting/:meetingId',
        loadChildren: () => import('./meeting/meeting.module').then(m => m.MeetingModule),
        canActivate: [AuthGuard],
        providers: [
          importProvidersFrom(
            StoreModule.forFeature(HeadlinesModuleStateKey, HeadlinesModuleStateReducer, {
              initialState: InitialHeadlinesModuleState,
            }),
            EffectsModule.forFeature([HeadlinesStateEffects, CascadingMessagesStateEffects])
          ),
        ],
      },
      {
        path: 'meetings',
        loadChildren: () => import('./meeting/meetings/meetings.module').then(m => m.MeetingsModule),
        canActivate: [AuthGuard, ActiveSubscriptionGuard],
      },
      {
        path: 'meetings-v2',
        loadChildren: () => import('./pages/meetings/_state/meetings-v2.module').then(m => m.MeetingsV2Module),
        canActivate: [AuthGuard, ActiveSubscriptionGuard],
      },
      {
        path: 'headlines',
        loadComponent: () => import('./headlines/headlines.component').then(c => c.HeadlinesComponent),
        data: { itemType: ItemType.headline },
        canActivate: [AuthGuard, ActiveSubscriptionGuard],
        providers: [
          importProvidersFrom(
            StoreModule.forFeature(HeadlinesModuleStateKey, HeadlinesModuleStateReducer, {
              initialState: InitialHeadlinesModuleState,
            }),
            EffectsModule.forFeature([HeadlinesStateEffects, CascadingMessagesStateEffects])
          ),
        ],
      },
      {
        path: 'headlines/:id',
        loadComponent: () => import('./headlines/headline/headline.component').then(c => c.HeadlineComponent),
        canActivate: [AuthGuard, ActiveSubscriptionGuard],
        resolve: { headline: HeadlineResolver },
      },
      {
        path: 'chart',
        loadChildren: () =>
          import('./accountability-chart/accountability-chart.module').then(m => m.AccountabilityChartModule),
        canActivate: [AuthGuard, ActiveSubscriptionGuard, PrivateAccountabilityChartGuard],
      },
      {
        path: 'insights',
        canActivate: [AuthGuard, ActiveSubscriptionGuard],
        loadChildren: () => import('./insights/insights.module').then(m => m.InsightsModule),
      },
      {
        path: '1-on-1',
        loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule),
        canActivate: [AuthGuard],
      },
      { path: 'conversation/:id/prepare-form', redirectTo: '/1-on-1/discussion/:id/form', pathMatch: 'full' },
      { path: 'feedback/quarterly', redirectTo: '/1-on-1/discussions/quarterly', pathMatch: 'full' },
      { path: 'feedback/annual', redirectTo: '/1-on-1/discussions/annual', pathMatch: 'full' },
      { path: 'feedback/fit-check', redirectTo: '/1-on-1/discussions/fit-check', pathMatch: 'full' },
      { path: 'feedback/fit-check-v2', redirectTo: '/1-on-1/discussions/fit-check-v2', pathMatch: 'full' },
      { path: 'feedback', redirectTo: '/1-on-1/discussions/quarterly', pathMatch: 'full' },
      {
        path: 'process',
        loadChildren: () => import('./process/process.module').then(m => m.ProcessModule),
        canActivate: [AuthGuard, ActiveSubscriptionGuard],
      },
      {
        path: 'directory',
        loadChildren: () => import('./directory/directory.module').then(m => m.DirectoryModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'directory-v2',
        loadChildren: () => import('./directory-v2/directory-v2.module').then(m => m.DirectoryV2Module),
        canActivate: [AuthGuard],
      },
      {
        path: 'mastery',
        loadChildren: () => import('./mastery/mastery.module').then(m => m.MasteryModule),
        canActivate: [AuthGuard, ActiveSubscriptionGuard, MasteryGuard],
      },
      {
        path: 'knowledge',
        loadChildren: () => import('./learning-modules/learning-modules.module').then(m => m.LearningModulesModule),
        canActivate: [AuthGuard, LearningModuleGuard],
      },
      {
        path: 'surveys',
        redirectTo: 'assessments',
      },
      {
        path: 'assessments',
        loadChildren: () => import('./pages/assessments/assessments-routing.module').then(m => m.SurveyRoutes),
        canActivate: [AuthGuard, ActiveSubscriptionGuard],
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'partner-hub',
        loadChildren: () => import('@ninety/partners/partner-hub/partner-hub.module').then(m => m.PartnerHubModule),
        canActivate: [AuthGuard, PartnerHubAccessGuard],
      },
      {
        path: 'my-account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'helpful',
        component: HelpfulComponent,
        canActivate: [AuthGuard, HelpfulPermissionsGuard.hasAnyHelpfulPermission],
        data: { title: 'Helpful' },
      },
      {
        path: 'become-a-partner/:partnerType',
        component: BecomeAPartnerComponent,
        canActivate: [AuthGuard, ActiveSubscriptionGuard],
        data: { title: 'Become a Partner!' },
      },
      {
        path: 'become-a-partner',
        component: BecomeAPartnerComponent,
        canActivate: [AuthGuard, ActiveSubscriptionGuard],
        data: { title: 'Become a Partner!' },
      },
      {
        path: 'partners',
        loadChildren: () => import('@ninety/partners/partner-management/partners.module').then(m => m.PartnersModule),
        canActivate: [AuthGuard, PartnersPermissionsGuard],
        title: 'Partners',
      },
      {
        path: 'detail',
        outlet: 'detail',
        loadChildren: () => import('./detail-view/detail-view.module').then(m => m.DetailViewModule),
      },
      // for preserving external links
      { path: 'accountability-chart', redirectTo: '/chart', pathMatch: 'full' },
      { path: 'vto', redirectTo: '/vision', pathMatch: 'full' },
      { path: 'insights', redirectTo: '/insights', pathMatch: 'full' },
      { path: '', redirectTo: '/home', pathMatch: 'full' },
    ],
  },

  // when releasing to prod, you need to add a redirect here
  { path: 'v4/my-90', redirectTo: '/home' },
  { path: 'v4/my-focus', redirectTo: '/home' },
  { path: 'v4/data', redirectTo: '/data/weekly' },
  { path: 'v4/rocks', redirectTo: '/rocks' },
  { path: 'v4/todos', redirectTo: '/todos' },
  { path: 'v4/issues', redirectTo: '/issues/short-term' },
  { path: 'v4/meetings', redirectTo: '/meetings' },
  { path: 'v4/vto', redirectTo: '/vto' },
  { path: 'v4/feedback', redirectTo: '/1-on-1/discussions/quarterly' },
  { path: 'v4/process', redirectTo: '/process' },
  { path: 'v4/directory', redirectTo: '/directory' },
  { path: 'v4/accountability-chart', redirectTo: '/accountability-chart' },
  { path: 'v4/login', redirectTo: 'login' },
  { path: 'v4/settings', redirectTo: '/settings' },
  { path: 'v4/account', redirectTo: '/my-account' },
  { path: 'billing', redirectTo: `/settings/billing/overview` },
  { path: '**', redirectTo: '' },
];
const routeConfig = { enableTracing: true };
@NgModule({
  imports: [RouterModule.forRoot(routes /*routeConfig*/)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
