<mat-dialog-content>
  <form [formGroup]="form" *ngrxLet="vm$ as vm" class="parent">
    <div class="header">
      <div class="flex-row title-row">
        <span class="terra-title-small">Schedule a {{ vm.language.meeting.item }}</span>
        <span>
          <ninety-button secondary stroked (click)="close()"><terra-icon icon="x" size="12" /></ninety-button>
        </span>
      </div>
      <div class="header">Select an agenda, date and presenter for your {{ vm.language.meeting.item }}.</div>
    </div>
    <div class="agenda">
      <mat-label>Agenda</mat-label>
      <mat-form-field appearance="outline">
        <mat-select [required]="true" formControlName="agenda" placeholder="Select Agenda">
          <mat-option *ngFor="let agenda of vm.agendas" [value]="agenda" class="agenda-item">{{
            agenda.displayText
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="date">
      <mat-label>Date</mat-label>
      <mat-form-field appearance="outline">
        <input
          matInput
          [min]="minDate"
          [matDatepicker]="dueDatePicker"
          required
          aria-label="date picker"
          formControlName="date"
          (dateChange)="dateChanged($event.value)" />
        <mat-datepicker-toggle matSuffix [for]="dueDatePicker">
          <terra-icon matDatepickerToggleIcon icon="calendar" />
        </mat-datepicker-toggle>
        <mat-datepicker #dueDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="time">
      <div>
        <mat-label>Time</mat-label>
        <div class="flex-row">
          <mat-form-field appearance="outline" class="time-entry">
            <input
              type="number"
              min="1"
              max="12"
              matInput
              cdkTextareaAutosize
              aria-label="hours"
              formControlName="hours"
              placeholder="hh" />
          </mat-form-field>
          <span>:</span>
          <mat-form-field appearance="outline" class="time-entry">
            <input
              type="number"
              min="0"
              max="59"
              matInput
              cdkTextareaAutosize
              aria-label="minutes"
              formControlName="minutes"
              placeholder="mm" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="period">
            <mat-select [required]="true" formControlName="period">
              <mat-option value="am">am</mat-option>
              <mat-option value="pm">pm</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="cadence">
      <mat-label>Repeating {{ vm.language.meeting.item }} Cadence</mat-label>
      <mat-form-field appearance="outline">
        <mat-select [required]="true" formControlName="cadence">
          <mat-option *ngFor="let option of cadence$ | async" class="agenda-item" value="{{ option.value }}">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="presenter">
      <mat-label>Presenter</mat-label>
      <mat-form-field appearance="outline">
        <mat-select [required]="true" formControlName="presenterId" [ngModel]="vm.currentUserId">
          <mat-option *ngFor="let user of vm.team" [value]="user.value">{{ user.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="flex-row">
  <ninety-button secondary stroked (click)="close()">Cancel</ninety-button>
  <ninety-button primary filled (click)="save()" [disabled]="!form.valid">Save</ninety-button>
</mat-dialog-actions>
