import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'terra-form-field',
  standalone: true,
  exportAs: 'terraFormField',
  imports: [CommonModule],
  templateUrl: './terra-form-field.component.html',
  styleUrls: ['./terra-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerraFormFieldComponent {}
