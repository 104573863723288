import { DefaultUrlSerializer } from '@angular/router';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { cloneDeep as _cloneDeep, groupBy as _groupBy } from 'lodash';

import { WidgetTemplateType } from '@ninety/my-ninety/_models/widget-template-type';
import { DetailType } from '@ninety/ui/legacy/shared/models/_shared/detail-type.enum';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { selectUrl } from '@ninety/ui/legacy/state/route.selectors';

import {
  RockModuleStateKey,
  RockModuleStateModel,
  milestonesStateAdapter,
  rocksStateAdapter,
} from './rocks-v2-state.model';

export const selectRocksModuleStateModel = createFeatureSelector<RockModuleStateModel>(RockModuleStateKey);

export const selectRocksState = createSelector(
  selectRocksModuleStateModel,
  state => state?.rocks ?? rocksStateAdapter.getInitialState()
);

export const selectMilestonesState = createSelector(
  selectRocksModuleStateModel,
  state => state?.milestones ?? milestonesStateAdapter.getInitialState()
);

export const { selectAll: selectAllRocks } = rocksStateAdapter.getSelectors();
export const { selectAll: selectAllMilestones } = milestonesStateAdapter.getSelectors();

export const selectRocks = createSelector(selectRocksState, selectAllRocks);
export const selectMilestones = createSelector(selectMilestonesState, selectAllMilestones);

export const selectFilterBy = createSelector(selectRocksModuleStateModel, state => state.filters);
export const selectFilterByUserId = createSelector(selectRocksModuleStateModel, state => state.filters.userId);

export const selectFilterByTeamId = createSelector(selectRocksModuleStateModel, state => state.filters.teamId);
// export const selectCompanyId = createSelector(selectCompanyState, state => state.company._id);
export const selectTotalCount = createSelector(selectRocksModuleStateModel, state => state.totalCount);

export const selectPagination = createSelector(selectRocksModuleStateModel, state => state.pagination);
export const selectSort = createSelector(selectRocksModuleStateModel, state => state.sort);
export const selectLoading = createSelector(selectRocksModuleStateModel, state => state.loading);
export const selectError = createSelector(selectRocksModuleStateModel, state => state.error);

export const selectFilterByRockStatus = createSelector(selectRocksModuleStateModel, state =>
  (state.filters.statusCode as string) === 'all' ? null : state.filters.statusCode
);
export const selectGetRockQueryParams = createSelector(
  selectFilterBy,
  selectPagination,
  selectSort,
  (filterBy, pagination, sort) => ({
    pageIndex: pagination.index,
    pageSize: pagination.size,
    ...(sort.direction != null ? { sortField: sort.field, sortDirection: sort.direction } : null),
    ...((filterBy.statusCode as string) === 'all' ? null : { statusCode: filterBy.statusCode }),
    ...(filterBy.teamId ? { teamId: filterBy.teamId } : null),
    ...(filterBy.userId ? { userId: filterBy.userId } : null),
  })
);

//TODO NEXT: we might need a different version for the regular rocks page
export const selectTemplateType = createSelector(
  selectLoading,
  selectError,
  selectTotalCount,
  selectFilterBy,
  (loading, error, totalCount, filterBy) => {
    if (loading) {
      return WidgetTemplateType.loading;
    }
    if (error) {
      return WidgetTemplateType.error;
    }
    if ((filterBy.teamId || filterBy.statusCode) && totalCount === 0) {
      return WidgetTemplateType.noQueryResults;
    }
    if (totalCount === 0) {
      return WidgetTemplateType.empty;
    }
    return WidgetTemplateType.data;
  }
);

export const selectSelectedRockId = createSelector(selectRocksModuleStateModel, state => state?.selectedRockId);
export const selectSelectedMilestoneId = createSelector(
  selectRocksModuleStateModel,
  state => state?.selectedMilestoneId ?? null
);

export const selectMilestonesByRock = createSelector(selectMilestones, milestones =>
  _groupBy(milestones, item => item.rockId)
);

export const selectUserMilestonesByRock = createSelector(selectMilestones, selectFilterByUserId, (milestones, userId) =>
  _groupBy(
    milestones.filter(m => m.ownedByUserId === userId),
    item => item.rockId
  )
);

// ToDo: Wrap all selectors in a const or namespace
export const RockSelectors = {
  selectRockById: (id: string) => createSelector(selectRocksState, rocksState => rocksState.entities[id]),
};
export const selectSelectedRock = createSelector(
  selectRocksState,
  selectSelectedRockId,
  (rocksState, selectedRockId) => rocksState.entities[selectedRockId]
);

export const selectMilestoneById = (id: string) =>
  createSelector(selectMilestonesState, milestonesState => milestonesState.entities[id]);
export const selectSelectedMilestone = createSelector(
  selectMilestonesState,
  selectSelectedMilestoneId,
  (milestonesState, selectedMilestoneId) => milestonesState.entities[selectedMilestoneId]
);

export const selectRockHasMilestoneForUser = (rockId: string, userId: string) =>
  createSelector(selectMilestonesForRock(rockId), milestones =>
    milestones.some(milestone => milestone.ownedByUserId === userId)
  );

export const selectMilestonesForRock = (id: string) =>
  createSelector(selectMilestones, milestones => milestones.filter(m => m.rockId === id));

export const selectRockDetailViewData = (id: string) =>
  createSelector(RockSelectors.selectRockById(id), selectMilestonesForRock(id), (rock, milestones) => {
    const rockToOpen = {
      type: DetailType.rock,
      input: {
        item: _cloneDeep({ ...rock, milestones }), //_cloneDeep needed so we can update from detail view
        itemType: ItemType.rock,
        itemUrl: `${location.origin}/rocks/${rock._id}`,
      },
    };

    return rockToOpen;
  });

export const selectRockV2DetailViewData = (id: string) =>
  createSelector(RockSelectors.selectRockById(id), selectMilestonesForRock(id), (rock, milestones) => {
    const rockToOpen = {
      type: DetailType.rockStoreV2,
      input: {
        item: _cloneDeep({ ...rock, milestones }), //_cloneDeep needed so we can update from detail view
        itemType: ItemType.rock,
        itemUrl: `${location.origin}/rocks/${rock._id}`,
      },
    };

    return rockToOpen;
  });

export const selectMilestoneDetailViewData = () =>
  createSelector(selectSelectedMilestoneId, milestoneId => {
    const milestoneToOpen = {
      type: DetailType.milestoneStore,
      input: {
        itemType: ItemType.milestone,
        itemUrl: `${location.origin}/milestones/${milestoneId}`,
      },
    };
    return milestoneToOpen;
  });

export const selectRockIdFromDetailRoute = createSelector(selectUrl, url => {
  const parsedUrl = new DefaultUrlSerializer().parse(url);
  if (
    parsedUrl?.root?.children?.detail?.segments[1]?.path === DetailType.rockStoreV2 &&
    parsedUrl.root.children.detail.segments[2]?.path
  )
    return parsedUrl.root.children.detail.segments[2].path;
  return null;
});

export const selectRockIdFromRoute = createSelector(selectUrl, url => {
  const parsedUrl = new DefaultUrlSerializer().parse(url);
  if (parsedUrl.root.children?.primary?.segments[0].path === 'rocks-v3')
    return parsedUrl.root.children.primary.segments[1]?.path;
  return null;
});

export const selectMilestoneIdFromRoute = createSelector(selectUrl, url => {
  const parsedUrl = new DefaultUrlSerializer().parse(url);
  if (parsedUrl.root.children?.primary?.segments[0].path === 'milestones')
    return parsedUrl.root.children.primary.segments[1]?.path;
  return null;
});
