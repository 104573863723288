import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, tap, catchError, of, switchMap, filter } from 'rxjs';

import { Cadence } from '@ninety/pages/meetings/models/cadence.enum';
import { selectCompanyId, selectLanguage } from '@ninety/ui/legacy/state/index';

import { MeetingsV2Service } from '../../_services/meetings-v2.service';
import {
  DeleteDialogOptions,
  DeleteMeetingScheduleDialogComponent,
  DeleteMeetingScheduleDialogData,
} from '../../meetings-page/meeting-schedules/delete-meeting-schedule-dialog/delete-meeting-schedule-dialog.component';
import { ScheduleMeetingDialogComponent } from '../../meetings-page/schedule-meeting-dialog/schedule-meeting-dialog.component';
import { MeetingSchedulingActions, MeetingsPageActions } from '../meetings.actions';
import { MeetingsStateSelectors } from '../meetings.selectors';

@Injectable()
export class MeetingSchedulingEffects {
  constructor(private actions$: Actions) {}
  private dialog = inject(MatDialog);
  private store = inject(Store);
  private meetingsV2Service = inject(MeetingsV2Service);

  openScheduleMeetingDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingSchedulingActions.openScheduleMeetingDialog),
        tap(() => {
          this.dialog.open(ScheduleMeetingDialogComponent);
        })
      ),
    { dispatch: false }
  );

  addMeetingSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingSchedulingActions.addMeetingSchedule),
      concatLatestFrom(() => [
        this.store.select(MeetingsStateSelectors.selectTeamId),
        this.store.select(selectCompanyId),
      ]),
      switchMap(([{ schedule }, teamId, companyId]) =>
        this.meetingsV2Service.addMeetingSchedule(companyId, { ...schedule, teamId }).pipe(
          map(() => MeetingSchedulingActions.addMeetingScheduleSuccess()),
          catchError((error: unknown) => of(MeetingSchedulingActions.addMeetingScheduleFailure({ error })))
        )
      )
    )
  );

  getMeetingSchedules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MeetingSchedulingActions.getMeetingSchedules,
        MeetingsPageActions.selectTeam,
        MeetingSchedulingActions.addMeetingScheduleSuccess,
        MeetingSchedulingActions.deleteMeetingScheduleSuccess
      ),
      concatLatestFrom(() => [
        this.store.select(MeetingsStateSelectors.selectTeamId),
        this.store.select(selectCompanyId),
      ]),
      switchMap(([, teamId, companyId]) =>
        this.meetingsV2Service.getMeetingSchedules({ teamId, companyId }).pipe(
          map(schedules => MeetingSchedulingActions.getMeetingSchedulesSuccess({ schedules })),
          catchError((error: unknown) => of(MeetingSchedulingActions.getMeetingSchedulesFailure({ error })))
        )
      )
    )
  );

  getMeetingSchedulesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingSchedulingActions.getMeetingSchedulesSuccess),
        map(({ schedules }) => this.meetingsV2Service.setMeetingSchedules(schedules))
      ),
    { dispatch: false }
  );

  openDeleteMeetingScheduleDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingSchedulingActions.openDeleteMeetingScheduleDialog),
      concatLatestFrom(() => [this.store.select(selectLanguage)]),
      switchMap(([{ schedule }, language]) =>
        this.dialog
          .open<DeleteMeetingScheduleDialogComponent, DeleteMeetingScheduleDialogData, DeleteDialogOptions>(
            DeleteMeetingScheduleDialogComponent,
            {
              data: {
                meetingLanguage: language.meeting,
                cadence: schedule.cadence,
                isRepeating: schedule.cadence !== Cadence.none,
              },
            }
          )
          .afterClosed()
          .pipe(
            filter(deleteOptions => !!deleteOptions),
            map(deleteOptions => {
              let canceledForDate: Date = null;
              if (deleteOptions.isRepeating && deleteOptions.deleteAll === false) {
                //a specific date when deleting schedule for a specific period
                canceledForDate = schedule.scheduledDate;
              }
              return MeetingSchedulingActions.deleteMeetingSchedule({
                scheduleId: schedule._id,
                isRepeating: deleteOptions.isRepeating,
                deleteAll: deleteOptions.deleteAll,
                ...(canceledForDate && { canceledForDate }),
              });
            })
          )
      )
    )
  );

  deleteMeetingSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingSchedulingActions.deleteMeetingSchedule),
      concatLatestFrom(() => [
        this.store.select(MeetingsStateSelectors.selectTeamId),
        this.store.select(selectCompanyId),
      ]),
      switchMap(([{ scheduleId, deleteAll, isRepeating, canceledForDate }, teamId, companyId]) =>
        this.meetingsV2Service
          .deleteMeetingSchedule(scheduleId, {
            teamId,
            companyId,
            deleteAll,
            isRepeating,
            ...(canceledForDate && {
              canceledForDate: new Date(canceledForDate).toISOString(),
            }),
          })
          .pipe(
            map(() => MeetingSchedulingActions.deleteMeetingScheduleSuccess()),
            catchError((error: unknown) => of(MeetingSchedulingActions.deleteMeetingScheduleFailure({ error })))
          )
      )
    )
  );
}
