import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { TippyDirective } from '@ngneat/helipopper';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { TerraAvatarModule, TerraIconModule } from '@ninety/terra';
import { CardModule } from '@ninety/ui/legacy/components/cards/card.module';
import { ButtonComponent } from '@ninety/ui/legacy/components/index';
import { ContactCardHoverDirective, UpcomingDatePipe, UserInitialsPipe } from '@ninety/ui/legacy/shared/index';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import {
  extractValueFromStore,
  selectCurrentUserIsManageeOrAbove,
  selectCurrentUserIsManagerOrAbove,
} from '@ninety/ui/legacy/state/index';

import { MeetingsV2Service } from '../../_services/meetings-v2.service';
import { MeetingSchedulingActions, MeetingDialogActions } from '../../_state/meetings.actions';
import { MeetingsStateSelectors } from '../../_state/meetings.selectors';
import { Cadence } from '../../models/cadence.enum';
import { MeetingSchedule } from '../../models/meeting-schedule.model';

import { MeetingSchedulesContextMenuComponent } from './meeting-schedules-context-menu/meeting-schedules-context-menu.component';
import { DisableLaunchSchedulePipe } from './pipes/disable-launch-schedule.pipe';

@Component({
  selector: 'ninety-meeting-schedules',
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    CardModule,
    MatTableModule,
    UpcomingDatePipe,
    TerraIconModule,
    TippyDirective,
    TerraAvatarModule,
    UserInitialsPipe,
    ButtonComponent,
    ContactCardHoverDirective,
    DisableLaunchSchedulePipe,
    MeetingSchedulesContextMenuComponent,
    MatMenuModule,
  ],
  templateUrl: './meeting-schedules.component.html',
  styleUrls: ['./meeting-schedules.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingSchedulesComponent implements OnInit {
  store = inject(Store);
  meetingService = inject(MeetingsV2Service);

  @ViewChild(MeetingSchedulesContextMenuComponent) contextMenu: MeetingSchedulesContextMenuComponent;

  vm$ = {
    selectedTeam: this.store.select(MeetingsStateSelectors.selectSelectedTeam),
    language: this.store.select(selectLanguage),
    meetingInProgress: this.store.select(MeetingsStateSelectors.selectMeetingInProgress),
  };

  private TABLE_COLUMNS = ['frontSpacer', 'date', 'time', 'agenda', 'presenter', 'launch', 'contextMenu', 'endSpacer'];
  private TABLE_COLUMNS_LAUNCH_ONLY = ['frontSpacer', 'date', 'time', 'agenda', 'presenter', 'launch', 'endSpacer'];
  private TABLE_COLUMNS_READ_ONLY = ['frontSpacer', 'date', 'time', 'agenda', 'presenter', 'endSpacer'];

  columns: typeof this.TABLE_COLUMNS;

  Cadence = Cadence;

  ngOnInit() {
    if (extractValueFromStore(this.store, selectCurrentUserIsManagerOrAbove)) {
      this.columns = this.TABLE_COLUMNS;
    } else if (extractValueFromStore(this.store, selectCurrentUserIsManageeOrAbove)) {
      this.columns = this.TABLE_COLUMNS_LAUNCH_ONLY;
    } else {
      this.columns = this.TABLE_COLUMNS_READ_ONLY;
    }

    this.store.dispatch(MeetingSchedulingActions.getMeetingSchedules());
  }

  createMeeting({ meetingType, agendaId }: MeetingSchedule) {
    this.store.dispatch(MeetingDialogActions.createMeeting({ meetingType, agendaId }));
  }

  onClickOpenContextMenu(event: MouseEvent, schedule: MeetingSchedule) {
    this.contextMenu.openMenu(event, schedule);
  }

  onDelete({ schedule }: { schedule: MeetingSchedule }) {
    this.store.dispatch(MeetingSchedulingActions.openDeleteMeetingScheduleDialog({ schedule }));
  }
}
