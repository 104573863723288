import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { DataImportSelectors } from '@ninety/data-import/_state/data-import-state.selectors';
import { DataImportActions } from '@ninety/data-import/_state/data-import.actions';
import { DataImportType } from '@ninety/data-import/models/data-import-types';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { selectTeamFilterOptions } from '@ninety/ui/legacy/state/composite-selectors/user-team.selectors';

@Component({
  selector: 'ninety-data-import',
  template: `
    <ninety-action-bar>
      <div
        left
        class="import-selects"
        *ngrxLet="{
          templateOptions: templateOptions$,
          showTeamFilter: showTeamFilter$,
          teamOptions: teamOptions$
        } as data">
        <ninety-quick-filter
          [options]="data.templateOptions"
          pillTitle="Import"
          [disabled]="data.templateOptions?.length <= 1"
          [pillIcon]="icon$ | ngrxPush"
          (selectionChange)="selectTemplate(asDataImportType($event))"></ninety-quick-filter>

        <ninety-quick-filter
          *ngIf="data.showTeamFilter"
          [options]="data.teamOptions"
          pillTitle="Team"
          (selectionChange)="selectTeam(asTeam($event))"></ninety-quick-filter>
      </div>

      <ninety-button stroked primary (click)="downloadCSVTemplate()">
        <terra-icon icon="download" size="20" />
        Download template
      </ninety-button>
    </ninety-action-bar>

    <ninety-one-schema-import></ninety-one-schema-import>
  `,
  styles: [
    `
      .import-selects {
        display: flex;
        gap: 8px;
      }
    `,
  ],
})
export class DataImportComponent implements OnInit, OnDestroy {
  protected readonly icon$ = this.store.select(DataImportSelectors.selectSelectedIcon);
  protected readonly templateOptions$ = this.store.select(DataImportSelectors.selectTemplateOptions);
  protected readonly showTeamFilter$ = this.store.select(DataImportSelectors.selectShowTeamFilter);
  protected readonly teamOptions$ = this.store.select(selectTeamFilterOptions);

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(DataImportActions.init());
  }

  ngOnDestroy() {
    this.store.dispatch(DataImportActions.destroy());
  }

  selectTemplate(option: DataImportType) {
    this.store.dispatch(DataImportActions.selectTemplate({ option }));
  }

  selectTeam({ _id: teamId }: Team) {
    this.store.dispatch(DataImportActions.selectTeam({ teamId }));
  }

  downloadCSVTemplate() {
    this.store.dispatch(DataImportActions.downloadCSVTemplate());
  }

  asDataImportType = (option): DataImportType => option as DataImportType;
  asTeam = (option): Team => option as Team;
}
