import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DataImportStateKey, DataImportStateModel } from '@ninety/data-import/_state/data-import-state.model';
import {
  CUSTOMER_FACING_DATA_IMPORT_TYPES,
  DataImportType,
  HELPFUL_ONLY_DATA_IMPORT_TYPES,
} from '@ninety/data-import/models/data-import-types';
import { OneSchemaTemplateKey } from '@ninety/data-import/models/one-schema-template-key';
import { QuickFilterOption } from '@ninety/ui/legacy/components/inputs/quick-filter/models/quick-filter-item.model';
import { selectCurrentUserIsAdminOrOwner } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectHasHelpfulDataImportPermissions } from '@ninety/ui/legacy/state/app-global/helpful-permissions/helpful-permissions.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';

export namespace DataImportSelectors {
  export const selectDataImportState = createFeatureSelector<DataImportStateModel>(DataImportStateKey);

  export const selectToken = createSelector(selectDataImportState, state => state.userJwt);

  export const selectTemplateKey = createSelector(selectDataImportState, state => state.templateKey);

  export const selectSelectedIcon = createSelector(selectDataImportState, state => state.selectedIcon);

  export const selectTemplateOptions = createSelector(
    selectLanguage,
    selectHasHelpfulDataImportPermissions,
    selectTemplateKey,
    (language, hasDataImportPermissions, templateKey): QuickFilterOption<DataImportType>[] =>
      (hasDataImportPermissions ? HELPFUL_ONLY_DATA_IMPORT_TYPES : CUSTOMER_FACING_DATA_IMPORT_TYPES).map(item => ({
        id: item.templateKey,
        displayText: item.label || language[item.languageKey][item.labelKey],
        item,
        icon: { name: item.icon, size: 20 },
        selected: item.templateKey === templateKey,
      }))
  );

  export const selectTeamId = createSelector(selectDataImportState, state => state.selectedTeamId);

  export const selectHasImportPermissions = createSelector(
    selectCurrentUserIsAdminOrOwner,
    selectHasHelpfulDataImportPermissions,
    (isAdminOrOwner, hasHelpfulDataUploadPermission) => isAdminOrOwner || hasHelpfulDataUploadPermission
  );

  export const selectShowTeamFilter = createSelector(
    selectTemplateKey,
    templateKey => templateKey !== OneSchemaTemplateKey.users
  );
}
