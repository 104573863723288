import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { UniversalCreateActions } from '@ninety/layouts/_state/universal-create/universal-create.actions';
import { MobileNavMenuComponent } from '@ninety/layouts/components/mobile-nav-menu/mobile-nav-menu.component';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { CreateDialogInput } from '@ninety/ui/legacy/shared/models/_shared/create-dialog-input-params';
import { DialogMode } from '@ninety/ui/legacy/shared/models/_shared/dialog-mode-types';
import { selectCurrentUserIsManageeOrAbove } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';

@Component({
  selector: 'ninety-page-header',
  standalone: true,
  imports: [CommonModule, ButtonComponent, PushPipe, MobileNavMenuComponent],
  styleUrls: ['./page-header.component.scss'],
  template: `<header class="ninety-page-header">
    <div>
      <ninety-mobile-nav-menu />
      <h1>{{ header }}</h1>
      <p [class.hide-on-small-screens]="shouldHideDescriptionOnMobile" class="ninety-page-header__description">
        {{ description }}
      </p>
    </div>
    <div>
      <ninety-button
        filled
        primary
        (click)="openUniversalCreateModal()"
        data-cy="top-toolbar_create-button"
        *ngIf="isManageeOrAbove$ | ngrxPush">
        Create
      </ninety-button>
    </div>
  </header>`,
})
export class PageHeaderComponent {
  @Input() header: string;
  @Input() description: string;

  /** Universal Create dialog data */
  @Input() data: CreateDialogInput = {};
  @Input() mode?: DialogMode;
  @Input() shouldHideDescriptionOnMobile = false;
  /** If you want to use a custom action instead of the universal create dialog */
  @Input() stopDefaultUniversalCreateAction = false;
  @Output() onUniversalCreateClick = new EventEmitter<void>();

  protected readonly isManageeOrAbove$ = this.store.select(selectCurrentUserIsManageeOrAbove);

  constructor(private store: Store) {}

  openUniversalCreateModal() {
    if (!this.stopDefaultUniversalCreateAction)
      this.store.dispatch(UniversalCreateActions.open({ data: this.data, mode: this.mode }));
    this.onUniversalCreateClick.emit();
  }
}
