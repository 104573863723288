import { OneSchemaTemplateKey } from '@ninety/data-import/models/one-schema-template-key';
import { TerraIconName } from '@ninety/terra';

export const DataImportStateKey = 'data-import';
export interface DataImportStateModel {
  userJwt: string;
  templateKey: OneSchemaTemplateKey;
  selectedIcon: TerraIconName;
  selectedTeamId: string;
}

export const initialDataImportState: DataImportStateModel = {
  userJwt: '',
  templateKey: OneSchemaTemplateKey.users,
  selectedIcon: 'to-dos',
  selectedTeamId: null,
};
