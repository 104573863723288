<div class="maz-message" [class.user-message]="message.sender === MazMessageSender.user">
  <div class="message-body">
    <div class="maz-icon"><terra-icon icon="sparkle" variant="fill" size="12" /></div>
    <div class="message" [innerHTML]="message | mazMessageHtml"></div>
    <terra-avatar [src]="avatarInfo.pictureURL" [initials]="avatarInfo.userInitials" size="small"></terra-avatar>
  </div>
  <div class="message-footer">
    <ninety-button
      compact
      secondary
      class="incorrect-btn"
      [disabled]="message.feedback?.flag === MazFeedbackFlag.incorrect"
      ninetyTooltip="This answer is incorrect"
      (click)="flagIncorrect()">
      <terra-icon
        *ngIf="message.feedback?.flag !== MazFeedbackFlag.incorrect; else incorrectIcon"
        icon="warning-circle"
        size="20" />
      <ng-template #incorrectIcon>
        <terra-icon icon="warning-circle" variant="fill" size="20" class="incorrect" />
      </ng-template>
    </ninety-button>
  </div>
</div>
