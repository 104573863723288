import { Injectable, inject } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';

import { PastMeetingsPageActions } from '@ninety/pages/meetings/_state/meetings.actions';
import { QuickFilterOption } from '@ninety/ui/legacy/components/index';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { MeetingAgenda } from '@ninety/ui/legacy/shared/index';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { Meeting } from '@ninety/ui/legacy/shared/models/meetings/meeting';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';

import { MeetingsPageActions } from './_state/meetings.actions';
import { MeetingsStateSelectors } from './_state/meetings.selectors';

@Injectable({
  providedIn: 'root',
})
export class MeetingsFacade {
  private store = inject(Store);
  private stateService = inject(StateService);

  teamsOptions$ = this.store.select(MeetingsStateSelectors.selectTeamFilterOptions);
  language$ = this.store.select(selectLanguage);
  loading$ = this.store.select(MeetingsStateSelectors.selectLoading);
  pastMeetings$ = this.store.select(MeetingsStateSelectors.selectAll);
  totalCount$ = this.store.select(MeetingsStateSelectors.selectTotalCount);
  templateType$ = this.store.select(MeetingsStateSelectors.selectTemplateType);
  page$ = this.store.select(MeetingsStateSelectors.selectPage);
  selectSortForTable$ = this.store.select(MeetingsStateSelectors.selectSortForTable);
  selectedId$ = this.store.select(MeetingsStateSelectors.selectSelectedId);
  meetingTypeOptions$ = this.store.select(MeetingsStateSelectors.selectCombinedAgendaOptionsWithAll);

  dateFormat = this.stateService.dateFormat;

  reset() {
    this.store.dispatch(MeetingsPageActions.reset());
  }

  teamSelected(team: Team | Team[]): void {
    this.store.dispatch(MeetingsPageActions.selectTeam({ team: team as Team }));
  }

  pastMeetingsTeamSelected(team: Team | Team[]): void {
    this.store.dispatch(PastMeetingsPageActions.selectTeam({ team: team as Team }));
  }

  meetingTypeSelected(options: QuickFilterOption<MeetingAgenda>[]) {
    const meetingAgenda = options.find(o => o.selected);
    this.store.dispatch(PastMeetingsPageActions.selectMeetingType({ meetingAgenda }));
  }

  printMeetingAgendas() {
    this.store.dispatch(MeetingsPageActions.printAgenda());
  }

  paginate($event: PageEvent) {
    this.store.dispatch(MeetingsPageActions.paginationChange({ index: $event.pageIndex, size: $event.pageSize }));
  }

  sortChange(sort: Sort) {
    this.store.dispatch(MeetingsPageActions.sortChange({ sort }));
  }

  onMeetingSelected(meeting: Meeting) {
    this.store.dispatch(MeetingsPageActions.selectMeeting({ meeting }));
  }
}
