<div
  class="terra-input terra-search-input"
  (click)="focus()"
  [ngClass]="{
    'terra-input--disabled': disabled,
    'terra-input--invalid': _terraErrorStateMatcher.isErrorState(_ngControl),
    'terra-input--readonly': readonly
  }">
  <terra-icon class="terra-input__prefix" icon="search" size="20" />
  <input
    type="search"
    #input
    class="terra-input__input"
    [attr.placeholder]="placeholder || null"
    [maxlength]="maxlength || null"
    [disabled]="disabled"
    [readonly]="readonly"
    [autocomplete]="autocomplete"
    [(ngModel)]="_inputValue"
    (ngModelChange)="_onModelChange($event)"
    (blur)="_touched()"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-describedby]="ariaDescribedby" />
  <!-- This should become a Terra Icon Button in the future -->
  <button
    *ngIf="_inputValue && _inputValue.length > 0 && !disabled"
    class="terra-search-input__clear"
    (click)="_clearInput()">
    <terra-icon class="terra-input__suffix" icon="x" size="20" />
  </button>
</div>
