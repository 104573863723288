<h1 mat-dialog-title>Delete {{ data.isRepeating ? 'repeating' : 'scheduled' }} {{ data.meetingLanguage.item }}</h1>
<mat-dialog-content>
  <div *ngIf="!data.isRepeating">
    <p>Are you sure you want to delete this scheduled {{ data.meetingLanguage.item }} ?</p>
  </div>

  <div *ngIf="data.isRepeating">
    <p>
      This {{ data.meetingLanguage.item }} is scheduled to repeat {{ data.cadence }}. Do you want to delete only this
      scheduled {{ data.meetingLanguage.item }} or all scheduled {{ data.meetingLanguage.items }}?
    </p>
    <mat-radio-group [(ngModel)]="deleteOption">
      <mat-radio-button [value]="DeleteOption.one"
        >Delete only this scheduled {{ data.meetingLanguage.item }}</mat-radio-button
      ><br />
      <mat-radio-button [value]="DeleteOption.all"
        >Delete all repeating scheduled {{ data.meetingLanguage.items }}</mat-radio-button
      >
    </mat-radio-group>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <ninety-button stroked mat-dialog-close class="right-space-10">Cancel</ninety-button>
  <ninety-button filled primary [mat-dialog-close]="true" (click)="onDelete()">Delete</ninety-button>
</mat-dialog-actions>
