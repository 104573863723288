import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraFormFieldComponent } from './terra-form-field.component';

@NgModule({
  imports: [CommonModule, TerraFormFieldComponent],
  exports: [TerraFormFieldComponent],
})
export class TerraFormFieldModule {}
