<h1 mat-dialog-title>Select the team</h1>
<mat-dialog-content *ngrxLet="selectedTeam$ as selectedTeam">
  <p>Which team is having this {{ stateService.language.meeting.item }}?</p>
  <mat-form-field appearance="outline" class="bottom-space-20">
    <mat-label>Team <span class="red">*</span></mat-label>
    <mat-select
      data-cy="meeting-start-dialog_team-select"
      class="team-select"
      [(ngModel)]="data.teamId"
      [compareWith]="teamsAreSame"
      (selectionChange)="selectTeam($event.value)">
      <mat-option *ngFor="let team of userTeams$ | async" [value]="team._id">{{ team.name }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{ stateService.language.meeting.item }} Type <span class="red">*</span></mat-label>
    <mat-select
      data-cy="meeting-start-dialog_agenda-select"
      class="team-select"
      [(ngModel)]="selectedAgendaId"
      (selectionChange)="selectMeetingType($event.value)">
      <mat-optgroup label="Default Agendas">
        <mat-option [value]="MeetingType.weekly" data-cy="meeting-start-dialog_weekly">{{
          stateService.language.meeting.levelTen
        }}</mat-option>
        <mat-option [value]="MeetingType.quarterly" data-cy="meeting-start-dialog_quarterly">{{
          stateService.language.meeting.quarterlySession
        }}</mat-option>
        <mat-option [value]="MeetingType.annualDayOne" data-cy="meeting-start-dialog_annual">{{
          stateService.language.meeting.annualSession
        }}</mat-option>
        <mat-option [value]="MeetingType.focusDay" data-cy="meeting-start-dialog_focus">{{
          stateService.language.meeting.focusDay
        }}</mat-option>
        <mat-option [value]="MeetingType.visionBuildingDayOne" data-cy="meeting-start-dialog_vision">{{
          stateService.language.meeting.visionBuildingDayOne
        }}</mat-option>
        <mat-option [value]="MeetingType.visionBuildingDayTwo" data-cy="meeting-start-dialog_goals">{{
          stateService.language.meeting.visionBuildingDayTwo
        }}</mat-option>
      </mat-optgroup>

      <mat-optgroup label="Custom Agendas" *ngIf="selectedTeam?.settings.custom">
        <mat-option [disabled]="true" *ngIf="!selectedTeam.settings.custom.length">No Custom Agendas</mat-option>
        <mat-option
          *ngFor="let agenda of selectedTeam.settings.custom | sortByName; let i = index"
          [value]="$any(agenda)._id"
          (click)="selectCustomAgenda($any(agenda))"
          [attr.data-cy]="'meeting-start-dialog_' + agenda.name">
          {{ agenda.name }}
        </mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngrxLet="selectedTeam$ as selectedTeam">
  <ninety-button-row>
    <ninety-button stroked data-cy="meeting-start-dialog_cancel-meeting-button" (click)="dialogRef.close()">
      Cancel
    </ninety-button>
    <ninety-button
      filled
      primary
      data-cy="meeting-start-dialog_begin-meeting-button"
      id="ut-start-meeting-btn"
      [disabled]="!selectedTeam || !selectedAgendaId || (data.type === MeetingType.other && !data.agendaId)"
      (click)="startMeeting()">
      Begin
    </ninety-button>
  </ninety-button-row>
</mat-dialog-actions>
