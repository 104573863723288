<div id="universal-create-dialog" *ngIf="mode$ | async as mode" [ngClass]="[mode]" data-cy="universal-create-dialogue">
  <mat-toolbar class="create-header flex-between">
    <div class="create-type">
      <h2 data-cy="universal-create_title">Create</h2>
      <ninety-item-type-select
        [itemType]="itemType"
        [disabled]="data.disableItemTypeSelect || mode === dialogModeEnum.collapsed"
        [showCascadedOption]="showCascadingMessageOption"
        (itemTypeChange)="setItemType($event)"
        attr.data-cy="item-type-select_{{ itemName }}"></ninety-item-type-select>
    </div>

    <div class="actions">
      <!-- underscore collapse -->
      <button
        data-cy="universal-create_minimize-modal"
        *ngIf="mode !== dialogModeEnum.collapsed"
        mat-button
        class="action collapse"
        (click)="onClickChangeMode(dialogModeEnum.collapsed)">
        <terra-icon icon="minimize" />
      </button>

      <!-- underscore maximize -->
      <!-- I don't know why/how this icon signifies maximizing, but they're copying GMail's compose dialog and that's what it uses -->
      <button
        data-cy="universal-create_maximize-modal"
        *ngIf="mode === dialogModeEnum.collapsed"
        mat-button
        class="action underscore-maximize"
        (click)="onClickChangeMode(dialogModeEnum.expanded)">
        <terra-icon icon="minimize" />
      </button>

      <!-- minimize -->
      <button
        data-cy="universal-create_minimize-to-mini-modal"
        *ngIf="mode === dialogModeEnum.expanded"
        mat-button
        class="action minimize"
        (click)="onClickChangeMode(dialogModeEnum.mini)">
        <terra-icon icon="arrows-in-simple" />
      </button>

      <!-- maximize -->
      <button
        data-cy="universal-create_maximize-modal"
        *ngIf="mode === dialogModeEnum.mini"
        mat-button
        class="action expand-to-full"
        (click)="onClickChangeMode(dialogModeEnum.expanded)">
        <terra-icon icon="arrows-out-simple" />
      </button>

      <!-- expand to mini -->
      <button
        *ngIf="mode === dialogModeEnum.collapsed"
        mat-button
        class="action expand-to-mini"
        (click)="onClickChangeMode(dialogModeEnum.mini)">
        <terra-icon icon="arrows-out-simple" />
      </button>

      <!-- close -->
      <button
        data-cy="universal-create_close-modal"
        mat-button
        mat-dialog-close
        class="action close"
        (click)="onClickClose()">
        <terra-icon icon="x" />
      </button>
    </div>
  </mat-toolbar>
  <ng-container *ngIf="item && mode !== dialogModeEnum.collapsed">
    <mat-dialog-content *ngIf="stateService.language as lang">
      <div class="main-content">
        <div class="flex-align-center flex-wrap">
          <span #userSelect class="user-select-icon">
            <ninety-user-select
              [userId]="item?.userId"
              [ninetyContactCardHover]="item?.userId"
              orientation="above"
              [users]="item?.isPersonal ? userService.users : teamUsers"
              [selectedUserIds]="selectedUserIds"
              [disableSaveIfNoSelectedUsers]="true"
              [disabled]="stateService.isLiteUser"
              [doNotFilterSelectedUsers]="true"
              [multiple]="(itemType === ItemType.todo || itemType === ItemType.rock) && !data.fromMilestone"
              [required]="true"
              [submitAllUsersOnEverySelect]="true"
              [updateAlways]="true"
              (selectUsersIds)="addUsers($event)"
              (removeUser)="removeSelectedUser()"
              (selectUser)="addUser($event)"></ninety-user-select>
            <mat-form-field class="title-form-field">
              <mat-label>Name the {{ itemName }}...</mat-label>
              <input
                cdkFocusInitial
                #itemTitle
                matInput
                required
                attr.aria-label="Name the {{ itemName }}..."
                [(ngModel)]="item.title"
                data-cy="universal-create_name-input"
                attr.data-cy-item="{{ itemName }}" />
            </mat-form-field>
          </span>
          <div data-cy="universal-create_due-date" class="user-title-date-div">
            <mat-form-field *ngIf="hasDueDate" class="due-date small-form-field">
              <input
                matInput
                placeholder="Due Date"
                required
                [matDatepicker]="dueDatePicker"
                [min]="today"
                [(ngModel)]="dueDate"
                aria-label="due date"
                data-cy="universal-create_due-date-input"
                (focus)="dueDatePicker.open()"
                (ngModelChange)="updateDueDate()" />
              <mat-datepicker-toggle matSuffix [for]="dueDatePicker">
                <terra-icon matDatepickerToggleIcon icon="calendar" size="16" />
              </mat-datepicker-toggle>
              <mat-datepicker #dueDatePicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field
              *ngIf="itemType === ItemType.issue"
              data-cy="universal-create_interval-select"
              class="small-form-field">
              <mat-label>Interval <span class="red">*</span></mat-label>
              <mat-select [(ngModel)]="item.intervalCode" placeholder="{{ lang.issue.item }}">
                <mat-option data-cy="universal-create_interval-short-term" [value]="IntervalCode.shortTerm">{{
                  lang.issue.shortTerm
                }}</mat-option>
                <mat-option data-cy="universal-create_interval-long-term" [value]="IntervalCode.longTerm">{{
                  lang.issue.longTerm
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <section class="description editor bottom-space-10">
          <ninety-text-editor
            [(text)]="item.description"
            data-cy="universal-create_description-input"></ninety-text-editor>
        </section>

        <div *ngIf="itemType === ItemType.issue" class="flex">
          <mat-form-field>
            <input
              matInput
              placeholder="Who"
              value=""
              [(ngModel)]="item.who"
              [matAutocomplete]="auto"
              (ngModelChange)="filterUserNames()"
              aria-label="who"
              data-cy="universal-create_item-who" />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let user of filteredUsers" [value]="user.fullName">
                <div class="autocomplete-user-option">
                  <terra-avatar [src]="user?.metadata?.picture?.url" [initials]="user | userInitials" />
                  <span class="user-name">{{ user?.fullName }}</span>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div *ngIf="itemType === ItemType.rock" data-cy="universal-create_status-select" class="flex">
          <mat-form-field>
            <mat-label>Status <span class="red">*</span></mat-label>
            <mat-select [(ngModel)]="item.statusCode" placeholder="{{ lang.rock.item }} Status">
              <mat-option
                attr.data-cy="universal-create_status-{{ status.label }}"
                [value]="status.code"
                *ngFor="let status of rockStatusService.statuses"
                [ngClass]="status.class">
                {{ status.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="create-select-options flex" *ngIf="!stateService.isLiteUser">
          <div
            data-cy="universal-create_team-list-select"
            class="headline-team-select__container"
            #headlineTeamSelect
            [style]="
              itemType === ItemType.cascadedMessage || itemType === ItemType.headline ? 'flex-grow:2' : 'flex-shrink:2'
            ">
            <mat-form-field *ngIf="itemType === ItemType.cascadedMessage || itemType === ItemType.headline">
              <!-- using 2 form fields as opposed to two mat-selects because mat-select[multiple] does not show label on init when ngIf applied and multiple cannot be changed after init -->
              <mat-label>Team <span class="red">*</span></mat-label>
              <mat-select multiple [(ngModel)]="item.teamIds" (selectionChange)="checkTeamsCount()">
                <mat-option
                  data-cy="universal-create_all-teams-button"
                  value="all"
                  (click)="toggleAllTeams()"
                  *ngIf="(itemType == ItemType.cascadedMessage ? teamsToCascade : teams)?.length > 1">
                  All
                </mat-option>
                <mat-option
                  *ngFor="let team of itemType === ItemType.cascadedMessage ? teamsToCascade : teams"
                  [value]="team._id">
                  <span attr.data-cy="universal-create_team-select-{{ team.name }}">
                    {{ team.name }}
                  </span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            data-cy="universal-create_team-select"
            class="regular-team-select__container"
            #teamSelect
            [style]="
              itemType !== ItemType.cascadedMessage && itemType !== ItemType.headline ? 'flex-grow:2' : 'flex-shrink:2'
            ">
            <mat-form-field *ngIf="itemType !== ItemType.cascadedMessage && itemType !== ItemType.headline">
              <mat-label>Team<span class="red">*</span></mat-label>
              <mat-select [(ngModel)]="item.teamId" (ngModelChange)="onTeamChange()" [disabled]="item?.isPersonal">
                <div *ngFor="let team of teams">
                  <mat-option [value]="team._id">
                    <span attr.data-cy="universal-create_team-select-{{ team.name }}" class="team-name">{{
                      team.name
                    }}</span>
                    <span *ngIf="!(selectedUserIds | ownerOnTeam : team._id)" class="not-on-team">
                      ({{ this.selectedUserIds.length > 1 ? 'one or more owners' : 'owner' }} not on team)
                    </span>
                  </mat-option>
                </div>
                <mat-optgroup label="Other Teams:" *ngIf="otherTeams?.length">
                  <mat-option *ngFor="let team of otherTeams" [value]="team._id">
                    <span class="team-name">{{ team.name }}</span>
                    <span *ngIf="!(selectedUserIds | ownerOnTeam : team._id)" class="not-on-team">
                      ({{ this.selectedUserIds.length > 1 ? 'one or more owners' : 'owner' }} not on team)
                    </span>
                  </mat-option>
                </mat-optgroup>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            *ngIf="itemType === ItemType.issue && stateService.company.settings.issueRating"
            data-cy="universal-create_priority-select"
            class="flex flex-grow-2">
            <mat-form-field class="issue-rating">
              <mat-label>Priority</mat-label>
              <mat-select
                [value]="item?.rating"
                [disabled]="!stateService.isManageeOrAbove || item?.archived"
                [(ngModel)]="item.rating">
                <mat-option data-cy="universal-create_priority-none" [value]="null">
                  <span class="sub-text">None</span>
                </mat-option>
                <mat-option data-cy="universal-create_priority-1" [value]="1">1</mat-option>
                <mat-option data-cy="universal-create_priority-2" [value]="2">2</mat-option>
                <mat-option data-cy="universal-create_priority-3" [value]="3">3</mat-option>
                <mat-option data-cy="universal-create_priority-4" [value]="4">4</mat-option>
                <mat-option data-cy="universal-create_priority-5" [value]="5">5</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-form-field class="additional-teams" *ngIf="itemType === ItemType.rock">
            <mat-label>Add to other teams</mat-label>
            <mat-select [(ngModel)]="item.additionalTeamIds" (selectionChange)="setAdditionalTeamIds($event)" multiple>
              <mat-option
                *ngFor="let team of teams"
                [value]="team._id"
                [disabled]="!(selectedUserIds | ownerOnTeam : team._id) || team._id === item?.teamId">
                <span class="team-name">{{ team.name }}</span>
                <span
                  *ngIf="
                    (itemType !== ItemType.rock || selectedUserIds?.length === 1) &&
                    teams &&
                    item?.userId &&
                    !(selectedUserIds | ownerOnTeam : team._id)
                  "
                  class="not-on-team">
                  (owner not on team)
                </span>
                <span *ngIf="team._id === item?.teamId" class="not-on-team"> (already assigned) </span>
              </mat-option>
              <mat-optgroup label="Other Teams:" *ngIf="otherTeams?.length">
                <mat-option
                  *ngFor="let team of otherTeams"
                  [value]="team._id"
                  [disabled]="!(selectedUserIds | ownerOnTeam : team._id) || team._id === item?.teamId">
                  <span class="team-name">{{ team.name }}</span>
                  <span
                    *ngIf="
                      (itemType !== ItemType.rock || selectedUserIds?.length === 1) &&
                      teams &&
                      item?.userId &&
                      !(selectedUserIds | ownerOnTeam : team._id)
                    "
                    class="not-on-team">
                    (owner not on team)
                  </span>
                  <span *ngIf="team._id === item?.teamId" class="not-on-team"> (already assigned) </span>
                </mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
          <terra-switch
            attr.data-cy="universal-create_company-rock-toggle-{{ itemType === ItemType.rock }}"
            class="company-departmental-option"
            *ngIf="itemType === ItemType.rock"
            [ngModel]="item?.levelCode === LevelCode.company"
            (change)="levelCodeChange($event)">
            {{ isSltTeam ? lang.rock.company : lang.rock.departmental }} {{ lang.rock.item }}
          </terra-switch>
          <terra-switch
            attr.data-cy="universal-create_personal-toggle-{{ item?.isPersonal }}"
            class="company-departmental-option"
            *ngIf="itemType === ItemType.todo && !data.fromMilestone"
            [(ngModel)]="item.isPersonal">
            {{ lang.todo.personal }}
          </terra-switch>
          <mat-form-field class="team-dropdown w180" *ngIf="(repeatTodosFlag$ | async) && itemType === ItemType.todo">
            <mat-label>Repeat</mat-label>
            <mat-select [(ngModel)]="item.repeat" [required]="true">
              <mat-option *ngFor="let enum of TodoRepeatType | keyvalue : returnZero" [value]="enum.value">
                <span class="team-name">{{ enum.value }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div
            class="flex flex-grow-2 is-public-container"
            *ngIf="itemType === ItemType.issue && isSltTeam && item?.intervalCode === IntervalCode.longTerm">
            <terra-switch
              class="company-departmental-option"
              matTooltip="Public {{
                lang.issue.longTerm + ' ' + lang.issue.items | lowercase
              }} will be visible to other teams when they're viewing the Leadership team's {{ lang.vto.item }}."
              matTooltipClass="ninety-tooltip"
              matTooltipPosition="above"
              [(ngModel)]="item.isPublic"
              >Public</terra-switch
            >
          </div>
        </div>
        <p
          class="team-filter-div"
          *ngIf="!stateService.isLiteUser && itemType !== ItemType.cascadedMessage && !item?.isPersonal">
          <i>* Note:</i>
          Changing teams will filter out selected users not on team
        </p>
        <div *ngIf="item?.comments?.length || item?.attachments?.length" class="copy-options-wrp flex">
          <label class="right-space-10">Copy:</label>
          <div fxFlex>
            <terra-checkbox
              [(ngModel)]="copyComments"
              *ngIf="item?.comments?.length"
              aria-label="Copy Comments"
              class="right-space-10"
              >Comments
            </terra-checkbox>
            <terra-checkbox
              data-cy="universal-create_copy-existing-attachments"
              [(ngModel)]="copyExistingAttachments"
              *ngIf="item?.attachments?.length"
              aria-label="Copy Existing Attachments"
              >Existing Attachments
            </terra-checkbox>
          </div>
        </div>

        <div data-cy="universal-create_linked-item-container" *ngIf="(linkedItemsFlag$ | async) && data.createdFrom">
          <mat-label>Linking to...</mat-label>
          <mat-card class="linking-item-card">
            <mat-card-content *ngIf="{ linkedItemOwner: linkedItemOwner$ | async } as vm">
              <terra-avatar
                [ninetyContactCardHover]="vm.linkedItemOwner?._id"
                [interactive]="true"
                [src]="vm.linkedItemOwner?.metadata?.picture?.url"
                [initials]="vm.linkedItemOwner | userInitials" />
              {{ linkedItemTitle }}
              <ninety-button
                flat
                class="remove-linking-item-reference"
                (click)="removeLinkingItemReference($event)"
                matTooltip="Remove Linking Item Reference"
                matTooltipPosition="above"
                matTooltipClass="ninety-tooltip">
                <terra-icon icon="x" size="20" />
              </ninety-button>
            </mat-card-content>
          </mat-card>
        </div>

        <ninety-attachments [fileAttachments]="item?.fileAttachments" [template]="'secondary'"></ninety-attachments>
      </div>
    </mat-dialog-content>
    <div class="actions-wrapper">
      <mat-dialog-actions>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->

        <div class="button-container">
          <ninety-button
            filled
            primary
            class="full-width center-all uppercase"
            [disabled]="loading || !itemIsValid()"
            (click)="checkForTooltipsAndSave()"
            data-cy="universal-create_button-item-create">
            Create{{ (linkedItemsFlag$ | async) && data.createdFrom ? ' linked' : '' }} {{ itemName }}
          </ninety-button>
        </div>

        <div class="cancel-button-container">
          <ninety-button
            data-cy="universal-cancel-button"
            stroked
            secondary
            class="full-width close-btn"
            [disabled]="loading"
            (click)="onClickClose()">
            Cancel
          </ninety-button>
        </div>
      </mat-dialog-actions>
    </div>
  </ng-container>
</div>
