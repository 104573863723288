import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TippyInstance, TippyService } from '@ngneat/helipopper';
import { CreateOptions } from '@ngneat/helipopper/lib/tippy.types';
import { Store } from '@ngrx/store';
import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { Observable, Subscription, filter, forkJoin, iif, map, of, switchMap, take, tap } from 'rxjs';

import { CreateDialogService } from '@ninety/_layouts/services/create-dialog.service';
import { UniversalCreateService } from '@ninety/_layouts/services/universal-create.service';
import { LinkedItemsActions } from '@ninety/detail-view/_shared/linked-items/_state/linked-items.actions';
import { MeetingService } from '@ninety/meeting/_shared/services/meeting.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { LibraryService } from '@ninety/ui/legacy/core/services/library.service';
import { RockStatusService } from '@ninety/ui/legacy/core/services/rock-status.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { UserService } from '@ninety/ui/legacy/core/services/user.service';
import { Attachment } from '@ninety/ui/legacy/shared/models/_shared/attachment';
import { AttachmentParentType } from '@ninety/ui/legacy/shared/models/_shared/attachment-parent-type';
import { CreateDialogInput } from '@ninety/ui/legacy/shared/models/_shared/create-dialog-input-params';
import { DialogMode } from '@ninety/ui/legacy/shared/models/_shared/dialog-mode-types';
import { Item } from '@ninety/ui/legacy/shared/models/_shared/item';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { IntervalCode } from '@ninety/ui/legacy/shared/models/issues/interval-code';
import {
  ItemsToLinkedItemEnumMap,
  LinkedItemTypeEnum,
} from '@ninety/ui/legacy/shared/models/linked-items/linked-item-type-enum';
import {
  LinkedCascadingMessageWithMeta,
  LinkedHeadlineWithMeta,
  LinkedIssueWithMeta,
  LinkedLearningTaskWithMeta,
  LinkedLearningTopicWithMeta,
  LinkedMilestoneWithMeta,
  LinkedRockWithMeta,
  LinkedTodoWithMeta,
} from '@ninety/ui/legacy/shared/models/linked-items/linked-items-response.model';
import { MeetingType } from '@ninety/ui/legacy/shared/models/meetings/meeting-type.enum';
import { RockLevelCode } from '@ninety/ui/legacy/shared/models/rocks/rock-level-code';
import { RockStatus } from '@ninety/ui/legacy/shared/models/rocks/rock-status';
import { RockStatusCode } from '@ninety/ui/legacy/shared/models/rocks/rock-status-code';
import { TodoRepeatType } from '@ninety/ui/legacy/shared/models/todos/todo-repeat-types';
import { FilterOutTeamPipe } from '@ninety/ui/legacy/shared/pipes/filter-out-team.pipe';
import { FeatureFlagFacade } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { teamAll } from '@ninety/ui/legacy/state/app-entities/team-list/api/team-list.model';
import { CurrentUserSelectors } from '@ninety/ui/legacy/state/app-entities/user-list/user-list-state.selectors';
import { UserSelectors, selectCurrentUser } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { TeamSelectors, TeamUserSelectors, UserModelState } from '@ninety/ui/legacy/state/index';
import { extractValueFromStore } from '@ninety/ui/legacy/state/state-util';

@Component({
  selector: 'ninety-universal-create',
  templateUrl: './universal-create.component.html',
  styleUrls: ['./universal-create.component.scss'],
})
export class UniversalCreateComponent implements OnInit, AfterViewInit, OnDestroy {
  item: Item;
  itemType?: ItemType;
  itemName = this.stateService.language.issue.item;
  today = new Date();
  users: User[];
  allUsers: User[];
  filteredUsers: User[];
  loading = false;
  selectedUserIds: string[] = [];
  teams: Team[];
  teamsToCascade: Team[]; // teams not including the filter toolbar selected team
  teamIdsToCascade: string[];
  otherTeams: Team[]; // teams not including the item's selected team
  teamIds: string[];
  allTeamsSelected = false;
  teamId$Sub: Subscription;
  teamUsers: User[] = [];
  status: RockStatus;
  isSltTeam: boolean;
  dueDate: string | Date;
  firstTeamId = true;
  usingCurrentUser: boolean;
  dataHasNoTeam: boolean;
  showCascadingMessageOption: boolean;
  copyComments: boolean;
  copyExistingAttachments: boolean;
  attachmentParentType: AttachmentParentType;
  mode$!: Observable<DialogMode>;
  currentUser: UserModelState;
  linkedItemTitle: string;

  @ViewChild('userSelect') ownerSelection: ElementRef;
  @ViewChild('itemTitle') itemTitle: ElementRef;
  @ViewChild('teamSelect') teamSelect: ElementRef;
  @ViewChild('headlineTeamSelect') headlineTeamSelect: ElementRef;

  // Tooltips
  ownerTip: TippyInstance;
  itemTitleTip: TippyInstance;
  teamSelectTip: TippyInstance;
  headlineTeamSelectTip: TippyInstance;
  tippyOptions: Partial<CreateOptions> = {
    showOnCreate: false,
    theme: 'warning',
    trigger: 'manual',
    onShown: instance => {
      setTimeout(() => {
        instance.hide();
      }, 3000);
    },
  };

  // Enums
  readonly dialogModeEnum = DialogMode;
  readonly ItemType = ItemType;
  readonly IntervalCode = IntervalCode;
  readonly LevelCode = RockLevelCode;

  private subscriptions = new Subscription();

  protected readonly TodoRepeatType = TodoRepeatType;
  repeatTodosFlag$ = this.featureFlags.getFlag(FeatureFlagKeys.repeatingTodos);
  linkedItemsFlag$ = this.featureFlags.getFlag(FeatureFlagKeys.linkedItems);
  linkedItemOwner$: Observable<User>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CreateDialogInput,
    public rockStatusService: RockStatusService,
    public filterService: FilterService,
    private universalCreateService: UniversalCreateService,
    public userService: UserService,
    public meetingService: MeetingService,
    private filterOutTeamPipe: FilterOutTeamPipe,
    public stateService: StateService,
    private dateAdapter: DateAdapter<any>,
    private tippyService: TippyService,
    private libraryService: LibraryService,
    private createDialogService: CreateDialogService,
    private featureFlags: FeatureFlagFacade,
    private store: Store
  ) {
    this.currentUser = extractValueFromStore(this.store, selectCurrentUser);
    this.item = this.getDefaultItem();
  }

  ngOnInit() {
    this.mode$ = this.createDialogService.currentMode$;

    if (!this.data) this.data = {};

    this.linkedItemTitle = this.item.title.slice(0);

    this.dueDate = this.item.dueDate;

    if (this.item.userId) {
      this.selectedUserIds = [this.item.userId];
      this.linkedItemOwner$ = this.store.select(UserSelectors.selectUserById(this.item.userId));
    }

    if (!this.item.userId && this.currentUser && !this.currentUser.isImplementer) {
      this.usingCurrentUser = true;
      this.selectedUserIds = [this.currentUser._id];
    }

    this.item.intervalCode =
      this.meetingService.currentMeeting?.inProgress &&
      [MeetingType.quarterly, MeetingType.annualDayOne, MeetingType.annualDayTwo].includes(
        this.stateService.meetingType
      )
        ? IntervalCode.longTerm
        : !!this.data.item?.intervalCode
        ? this.data.item?.intervalCode
        : this.stateService.isShortTerm$.value
        ? IntervalCode.shortTerm
        : IntervalCode.longTerm;

    this.setItemType(this.data.itemType);

    if (this.data.item && this.data.item.isPersonal && this.data.itemType === ItemType.todo) {
      this.item.isPersonal = this.data.item.isPersonal;
    }

    this.status = this.rockStatusService.getStatus(this.rockStatusService.onTrack);

    this.addSubscriptions();
  }

  private getDefaultItem(): Item {
    const selectedTeamId = extractValueFromStore(this.store, TeamSelectors.selectFilterBarTeamId);

    return new Item({
      userId: !this.currentUser?.isImplementer ? this.currentUser._id : null,
      levelCode: this.stateService.isCompanyRock ? RockLevelCode.company : RockLevelCode.user,
      itemType: this.stateService.itemType,
      isPersonal: this.stateService.itemType === ItemType.todo && this.stateService.isPersonal,
      teamId: selectedTeamId !== teamAll._id ? selectedTeamId : null,
      ...this.data?.item,
    });
  }

  private addSubscriptions() {
    this.teamId$Sub = this.store.select(TeamSelectors.selectFilterBarTeamId).subscribe((teamId: string) => {
      if (teamId !== teamAll._id) {
        if (!this.item.teamId) {
          this.isSltTeam = teamId === this.stateService.company.seniorLeadershipTeamId;
          this.item.teamId = teamId;

          if (!this.isSltTeam && this.item.isPublic) {
            this.item.isPublic = false;
          }
        }

        if (
          (!this.firstTeamId || this.usingCurrentUser) &&
          this.itemType !== ItemType.cascadedMessage &&
          !this.item.teamIds?.length
        ) {
          this.item.teamIds = [teamId];
        }
      }
    });

    // Initialize team users
    this.teamUsers = extractValueFromStore(this.store, TeamUserSelectors.selectTeamUsers(this.item.teamId));
    this.addUserIdToTeamIfNeeded();

    this.subscriptions.add(
      this.store.select(CurrentUserSelectors.selectTeams).subscribe({
        next: teams => {
          if (this.itemType !== ItemType.cascadedMessage && this.itemType !== ItemType.headline) {
            this.reArrangeTeams();
          } else {
            this.teams = teams;
            this.teamIds = teams.map((t: Team) => t._id);
          }
          this.setOtherTeams();
        },
      })
    );

    this.subscriptions.add(
      this.store.select(TeamSelectors.selectAll).subscribe({
        next: teams => {
          if (this.itemType !== ItemType.cascadedMessage && this.itemType !== ItemType.headline) {
            this.reArrangeTeams(true);
          } else {
            const selectedTeamId = extractValueFromStore(this.store, TeamSelectors.selectFilterBarTeamId);
            this.teamsToCascade = this.filterOutTeamPipe.transform(teams, selectedTeamId);
            this.teamIdsToCascade = this.teamsToCascade.map((t: Team) => t._id);
          }
          this.setOtherTeams();
        },
      })
    );

    this.subscriptions.add(this.stateService.localeChange$.subscribe({ next: l => this.dateAdapter.setLocale(l) }));

    this.subscriptions.add(
      this.createDialogService.backdropClicked$
        .asObservable()
        .pipe(tap(() => this.onClickChangeMode(DialogMode.mini)))
        .subscribe()
    );

    this.subscriptions.add(
      this.linkedItemsFlag$.subscribe({
        next: flag => !flag && delete this.data.createdFrom,
      })
    );
  }

  ngAfterViewInit() {
    // TODO: Verify this still works
    if (this.ownerSelection?.nativeElement) {
      this.ownerTip = this.tippyService.create(
        this.ownerSelection.nativeElement,
        'Please assign an owner.',
        this.tippyOptions
      );
    }

    if (this.itemTitle?.nativeElement) {
      this.itemTitleTip = this.tippyService.create(
        this.itemTitle.nativeElement,
        'Please assign a title.',
        this.tippyOptions
      );
    }

    if (!this.stateService.isLiteUser && this.teamSelect?.nativeElement && this.headlineTeamSelect?.nativeElement) {
      this.teamSelectTip = this.tippyService.create(
        this.teamSelect.nativeElement,
        'Please select a team.',
        this.tippyOptions
      );
      this.headlineTeamSelectTip = this.tippyService.create(
        this.headlineTeamSelect.nativeElement,
        'Please select a team.',
        this.tippyOptions
      );
    }
  }

  ngOnDestroy(): void {
    this.ownerTip?.destroy();
    this.itemTitleTip?.destroy();
    if (!this.stateService.isLiteUser) {
      this.teamSelectTip?.destroy();
      this.headlineTeamSelectTip?.destroy();
    }
    this.teamId$Sub.unsubscribe();
    this.subscriptions.unsubscribe();
    this.createDialogService.handleUnsafeClose();
  }

  onClickClose() {
    this.createDialogService.close();
  }

  onClickChangeMode(mode: DialogMode) {
    const data: CreateDialogInput = {
      item: this.item,
      itemType: this.itemType,
      fromMilestone: this.data.fromMilestone,
      disableItemTypeSelect: this.data.disableItemTypeSelect,
      createdFrom: this.data.createdFrom,
    };

    this.createDialogService.changeMode(mode, data);
  }

  filterUserNames() {
    if (this.allUsers && this.item.who) {
      this.filteredUsers = this.allUsers.filter((user: User) =>
        user.fullName.toLowerCase().includes(this.item.who.toLowerCase())
      );
    }
  }

  get hasDueDate() {
    return this.itemType === ItemType.todo || this.itemType === ItemType.rock;
  }

  onTeamChange() {
    this.isSltTeam = this.item.teamId === this.stateService.company.seniorLeadershipTeamId;
    this.teamUsers = extractValueFromStore(this.store, TeamUserSelectors.selectTeamUsers(this.item.teamId));
    if (!this.ownerOnTeam()) {
      this.item.userId = null;
      this.selectedUserIds = [];
    }
  }

  setAdditionalTeamIds(event: any) {
    //* the following is for an edge case.
    if (event.value.includes(this.item.teamId)) {
      this.item.additionalTeamIds = event.value.filter((teamId: string) => teamId !== this.item.teamId);
    } else this.item.additionalTeamIds = event.value;
    // * if they aren't going to use it why have it...?
    if (!this.item.additionalTeamIds.length) delete this.item.additionalTeamIds;
  }

  addUsers(userIds: string[]) {
    this.selectedUserIds = userIds;
    this.item.userId = userIds[0];
    if (this.itemType !== ItemType.cascadedMessage && this.itemType !== ItemType.headline) {
      this.reArrangeTeams();
      this.setOtherTeams();
    }
  }

  addUser(user: User) {
    this.item.userId = user._id;
    this.selectedUserIds = [user._id];
    if (this.itemType !== ItemType.cascadedMessage && this.itemType !== ItemType.headline) {
      this.reArrangeTeams();
      this.setOtherTeams();
    }
  }

  close(res: Item | Item[] | null): void {
    this.loading = false;
    this.createDialogService.close(res);
  }

  levelCodeChange(e: boolean) {
    this.item.levelCode = e ? RockLevelCode.company : RockLevelCode.user;
  }

  ownerOnTeam(teamId = this.item.teamId): boolean {
    return (
      this.teamUsers &&
      this.selectedUserIds.every((id: string) => !!this.teamUsers.find((user: User) => user._id === id))
    );
  }

  toggleAllTeams() {
    this.allTeamsSelected = !this.allTeamsSelected;
    const teamIds = this.item.isCascadedMessage ? this.teamIdsToCascade : this.teamIds;
    this.item.teamIds = this.allTeamsSelected ? ['all', ...teamIds] : [];
  }

  checkTeamsCount() {
    const totalTeams = this.item.isCascadedMessage ? this.teamIdsToCascade.length : this.teamIds.length;
    const totalSelected = (this.item.teamIds || []).filter(id => id !== 'all').length;
    if (totalSelected === totalTeams) {
      if (this.item.teamIds.includes('all')) {
        this.item.teamIds = this.item.teamIds.filter((id: string) => id !== 'all');
      } else {
        this.item.teamIds = ['all', ...this.item.teamIds];
      }
    } else {
      this.item.teamIds = this.item.teamIds.filter((id: string) => id !== 'all');
    }
  }

  saveItem() {
    if (!this.copyComments) delete this.item.comments;
    if (!this.copyExistingAttachments || !this.item?.fileAttachments) {
      delete this.item.attachments;
    }

    if (this.loading) return;

    this.loading = true;

    const fullName = this.currentUser?.fullName;

    if (this.data.fromMilestone) {
      this.item.milestoneId = this.data.item.milestoneId;
      this.item.createdBy = this.stateService.language.milestone.item;
    } else {
      this.item.createdBy = fullName;
    }

    const dueDateJustBeforeMidnight = new Date(this.dueDate);
    dueDateJustBeforeMidnight.setHours(23, 59, 59, 59);
    this.item.originalDueDate = new Date(dueDateJustBeforeMidnight);
    //** save todo due dates as utc dates so we don't lose time zone information */
    this.item.dueDate =
      this.item.itemType !== ItemType.todo
        ? moment(this.dueDate).format('YYYY-MM-DD')
        : new Date(dueDateJustBeforeMidnight);

    if (this.itemType === ItemType.headline || this.itemType === ItemType.cascadedMessage) {
      this.item.ownedByUserId = this.item.userId;
      this.item.cascadedFromTeamId = null;

      const selectedTeamId = extractValueFromStore(this.store, TeamSelectors.selectFilterBarTeamId);
      if (this.itemType === ItemType.cascadedMessage && selectedTeamId !== teamAll._id)
        this.item.cascadedFromTeamId = selectedTeamId;
    }

    if (this.itemType === ItemType.cascadedMessage || this.itemType === ItemType.headline) {
      if (this.item.teamIds.includes('all')) {
        this.item.teamIds.splice(this.item.teamIds.indexOf('all'), 1);
      }
    }

    if (this.item.isPersonal || this.stateService.isLiteUser) this.item.teamId = null;

    this.universalCreateService
      .create(this.itemType, this.item, this.selectedUserIds, this.data.createdFrom)
      .pipe(
        switchMap((item: Item | Item[]) =>
          iif(
            () => !!this.item?.fileAttachments?.length && this.itemType !== ItemType.todo,
            forkJoin([...this.handleFileUploads(item)]).pipe(map(attachments => ({ item, attachments }))),
            of({ item })
          )
        )
      )
      .subscribe({
        next: ({ item, attachments }: { item: Item | Item[]; itemId: string; attachments: Attachment[] }) => {
          if (this.itemType !== ItemType.todo) {
            if (attachments?.length) {
              if (Array.isArray(item)) {
                item = item.map((i: Item) => {
                  const itemAttachments: Attachment[] = attachments.filter((a: Attachment) => i._id === a.parentId);
                  if (itemAttachments) {
                    if (i.attachments) {
                      return { ...i, attachments: [...i.attachments, ...itemAttachments] };
                    } else {
                      return { ...i, attachments: itemAttachments };
                    }
                  }
                });
              } else {
                if (item.attachments) item.attachments.push(...attachments);
                else item['attachments'] = attachments;
              }
            }
          }
          if (this.data.createdFrom) {
            const linkedItemType = ItemsToLinkedItemEnumMap[ItemType[this.itemType]] as LinkedItemTypeEnum;

            if (Array.isArray(item)) {
              item.map((i: Item) => {
                const linkedItem = this.generateLinkedItemData(linkedItemType, i);
                this.store.dispatch(
                  LinkedItemsActions.createdLinkedItem({
                    linkedItem: {
                      id: this.data.createdFrom.id,
                      linkedItemType,
                      linkedItemId: i._id,
                      linkedItem,
                    },
                  })
                );
              });
            } else {
              const linkedItem = this.generateLinkedItemData(linkedItemType, item);
              this.store.dispatch(
                LinkedItemsActions.createdLinkedItem({
                  linkedItem: {
                    id: this.data.createdFrom.id,
                    linkedItemType,
                    linkedItemId: item._id,
                    linkedItem,
                  },
                })
              );
            }
          }

          this.close(item);
        },
        error: () => (this.loading = false),
      });

    this.mode$
      .pipe(
        filter(mode => !!mode),
        take(1),
        switchMap(currentMode => this.userService.updateDialogPreference(currentMode))
      )
      .subscribe();
  }

  private generateLinkedItemData(linkedItemType: LinkedItemTypeEnum, item: Item) {
    switch (linkedItemType) {
      case LinkedItemTypeEnum.todo:
        return {
          _id: item._id,
          title: item.title,
          userId: item.userId,
          dueDate: item.dueDate,
          completed: item.completed,
        } as LinkedTodoWithMeta;
      case LinkedItemTypeEnum.issue:
        return {
          _id: item._id,
          title: item.title,
          userId: item.userId,
          completed: item.completed,
        } as LinkedIssueWithMeta;
      case LinkedItemTypeEnum.headline:
        return {
          _id: item._id,
          title: item.title,
          ownedByUserId: item.ownedByUserId,
          isDone: item.isDone,
        } as LinkedHeadlineWithMeta;
      case LinkedItemTypeEnum.rock:
        return {
          _id: item._id,
          title: item.title,
          userId: item.userId,
          dueDate: item.dueDate,
          completed: item.completed,
        } as LinkedRockWithMeta;
      case LinkedItemTypeEnum.cascadingMessage:
        return {
          _id: item._id,
          title: item.title,
          ownedByUserId: item.ownedByUserId,
          isDone: item.isDone,
        } as LinkedCascadingMessageWithMeta;
      case LinkedItemTypeEnum.learningTask:
        return {
          _id: item._id,
          title: item.title,
          ownedByUserId: item.ownedByUserId,
          completed: item.completed,
        } as LinkedLearningTaskWithMeta;
      case LinkedItemTypeEnum.learningTopic:
        return {
          _id: item._id,
          title: item.title,
          ownedByUserId: item.ownedByUserId,
          completed: item.completed,
        } as LinkedLearningTopicWithMeta;
      case LinkedItemTypeEnum.milestone:
        return {
          _id: item._id,
          title: item.title,
          ownedByUserId: item.ownedByUserId,
          isDone: item.isDone,
          dueDate: item.dueDate,
          rockId: item.rock?._id,
        } as LinkedMilestoneWithMeta;
    }
  }

  handleFileUploads(item: Item | Item[]): Observable<Attachment>[] {
    const observables: Observable<Attachment>[] = [];
    if (Array.isArray(item)) {
      item.forEach((i: Item) =>
        observables.push(
          ...this.libraryService.uploadFiles(i._id, this.attachmentParentType, this.item.fileAttachments, i)
        )
      );
    } else {
      observables.push(
        ...this.libraryService.uploadFiles(item._id, this.attachmentParentType, this.item.fileAttachments, item)
      );
    }

    return observables;
  }

  updateDueDate() {
    this.item.dueDate = this.dueDate;
  }

  checkForTooltipsAndSave() {
    if (
      this.item.userId &&
      this.item.title?.length &&
      (this.item.isPersonal || this.item.teamId || this.item.teamIds?.length)
    ) {
      if (this.itemIsValid()) this.saveItem();
    } else {
      if (!this.item.userId) this.ownerTip.show();
      if (!this.item.title?.length) this.itemTitleTip.show();

      if (this.itemType === ItemType.headline || this.itemType === ItemType.cascadedMessage) {
        if (!this.item.teamIds?.length) this.headlineTeamSelectTip.show();
      } else {
        if (!this.item.teamId) this.teamSelectTip.show();
      }
    }
  }

  itemIsValid(): boolean {
    if (!this.item.title?.length) return false;

    if (this.itemType === ItemType.headline || this.itemType === ItemType.cascadedMessage) {
      return this.item.teamIds && !!this.item.teamIds.length;
    }

    if (!this.stateService.isLiteUser && !this.item.isPersonal && !this.ownerOnTeam()) return false;

    return (
      (this.item.isPersonal || (!this.item.isPersonal && !!this.item.teamId)) &&
      (this.hasDueDate ? moment(this.dueDate).isValid() : true)
    );
  }

  setItemType(type: ItemType = this.stateService.isLiteUser ? ItemType.todo : this.stateService.itemType) {
    if (this.itemTitle?.nativeElement) {
      setTimeout(() => this.itemTitle.nativeElement.focus());
    }

    this.dueDate = this.item.dueDate = null; //reset dueDate on type set

    // Handle case where filter-toolbar is 'all' but item is targeting slt
    this.isSltTeam = this.item.teamId === this.stateService.company.seniorLeadershipTeamId;

    if (
      // Clear out teamIds when toggling between headlines/cascaded messages
      (type === ItemType.headline && this.item.itemType === ItemType.cascadedMessage) ||
      (type === ItemType.cascadedMessage && this.item.itemType === ItemType.headline)
    ) {
      this.item.teamIds = [];
    }

    this.itemType = type;
    this.item.itemType = type;

    if (type !== ItemType.todo) {
      // Prevents team-select from being stuck as disabled if they switch types after marking isPersonal
      this.item.isPersonal = null;
    }

    if (type !== ItemType.headline && type !== ItemType.cascadedMessage) {
      this.item.teamIds = [];
      this.reArrangeTeams();
      this.reArrangeTeams(true);
    } else {
      const selectedTeamId = extractValueFromStore(this.store, TeamSelectors.selectFilterBarTeamId);
      this.teams = extractValueFromStore(this.store, CurrentUserSelectors.selectTeams);
      this.teamIds = (this.teams ?? []).map((t: Team) => t._id);

      this.teamsToCascade = this.filterOutTeamPipe.transform(
        extractValueFromStore(this.store, TeamSelectors.selectAll) ?? [],
        selectedTeamId
      );
      this.teamIdsToCascade = (this.teamsToCascade ?? []).map((t: Team) => t._id);
    }
    this.setOtherTeams();

    if (type !== ItemType.issue) {
      this.item.isPublic = null;
      this.item.who = '';
    } else if (!this.isSltTeam) {
      this.item.isPublic = false;
    }

    if (type !== ItemType.rock) {
      this.item.additionalTeamIds = [];
      this.item.levelCode = null;
      this.item.statusCode = null;
      this.item.milestones = [];
    }

    const language = this.stateService.language;

    switch (type) {
      case ItemType.todo:
        this.attachmentParentType = 'To-Do';
        this.itemName = language.todo.item;
        this.item.repeat = TodoRepeatType.DontRepeat;
        this.item.seriesId = null;
        break;
      case ItemType.cascadedMessage:
        this.attachmentParentType = 'Headline';
        this.itemName = language.cascadingMessage.item;
        if (this.teamIdsToCascade && this.teamIdsToCascade.length === 1) {
          this.item.teamIds = [...this.teamIdsToCascade];
        }
        break;
      case ItemType.rock:
        this.attachmentParentType = 'Rock';
        this.itemName = language.rock.item;
        this.item.statusCode = RockStatusCode.onTrack;
        this.item.levelCode = this.stateService.isCompanyRock ? RockLevelCode.company : RockLevelCode.user;
        this.item.additionalTeamIds = [];
        this.item.milestones = [];
        break;
      case ItemType.headline:
        this.attachmentParentType = 'Headline';
        this.itemName = language.headline.item;
        if (this.item.teamId && !this.item.teamIds?.length) {
          this.item.teamIds = [this.item.teamId];
        }
        break;
      case ItemType.milestone:
        this.itemName = language.milestone.item;
        break;
      case ItemType.issue:
      default:
        this.attachmentParentType = 'Issue';
        this.itemName = language.issue.item;
        break;
    }

    this.item.isCascadedMessage = type === ItemType.cascadedMessage;
    // if cascading message was ever allowed, I want to make sure it stays as an option
    if (this.item.isCascadedMessage) this.showCascadingMessageOption = true;
    const index = this.item.teamIds && this.item.teamIds.indexOf(this.item.teamId);
    if (this.item.isCascadedMessage && index !== -1 && this.teamIdsToCascade && this.teamIdsToCascade.length > 1) {
      this.item.teamIds?.splice(index, 1);
    }

    this.setDueDate();

    if (this.headlineTeamSelectTip) this.toggleTippyTips();

    if (type === ItemType.issue && !this.allUsers) {
      this.userService.fetchUsersInBackground();
      this.userService.users$.pipe(filter(u => !!u)).subscribe({
        next: (users: User[]) => {
          const allUsers = _cloneDeep(users);
          this.allUsers = allUsers.map((user: User) => {
            const firstName = user.metadata.name.first || '';
            const lastName = user.metadata.name.last || '';
            user.fullName = `${firstName} ${lastName}`;
            return user;
          });
        },
      });
    }
  }

  toggleTippyTips() {
    if (this.itemType === ItemType.cascadedMessage || this.itemType === ItemType.headline) {
      this.headlineTeamSelectTip.enable();
      this.teamSelectTip.disable();
    } else {
      this.headlineTeamSelectTip.disable();
      this.teamSelectTip.enable();
    }
  }

  private setDueDate() {
    if (!this.data.fromMilestone && !this.dueDate) {
      if (this.itemType === ItemType.rock) {
        this.item.dueDate = this.dueDate = this.universalCreateService.getDueDate(ItemType.rock);
      } else if (this.itemType === ItemType.todo) {
        this.item.dueDate = this.dueDate = this.universalCreateService.getDueDate(ItemType.todo);
      }
    }
  }

  removeSelectedUser(): void {
    this.item.userId = null;
  }

  setOtherTeams(): void {
    if (!this.teams || !this.teamsToCascade?.length) return;
    this.otherTeams = this.teamsToCascade.filter(t => !this.teamIds.includes(t._id));
  }

  addUserIdToTeamIfNeeded(): void {
    //lite users do not have a team
    if (this.stateService.isLiteUser) return;

    if (!this.item.isPersonal && this.item.userId && this.item.teamId && this.item.teamId !== 'all') {
      const userIdInTeam = !!this.teamUsers.find(u => u._id === this.item.userId);
      if (!userIdInTeam) {
        const user = this.userService.allUsers.find(u => u._id === this.item.userId);
        if (user) {
          this.teamUsers = [user, ...(this.teamUsers ?? [])];
        }
      }
    }
  }

  removeLinkingItemReference(e: Event) {
    e.preventDefault();
    if (this.data.createdFrom) {
      delete this.data.createdFrom;
    }
  }

  protected returnZero(): number {
    return 0;
  }

  reArrangeTeams(teamsToCascade = false) {
    const selectedTeamId = extractValueFromStore(this.store, TeamSelectors.selectFilterBarTeamId);

    this.store
      .select(TeamSelectors.selectAll)
      .pipe(take(1))
      .subscribe(teams => {
        let ts = [];
        if (teamsToCascade) ts = this.filterOutTeamPipe.transform(teams, selectedTeamId);
        else ts = teams;
        const t = ts.map(t1 => ({ ...t1, ownerOnTeam: this.ownerOnTeam(t1._id) }));
        t.sort((a, b) => {
          if (a.ownerOnTeam === b.ownerOnTeam) {
            return 0;
          } else if (a.ownerOnTeam) {
            return -1;
          } else {
            return 1;
          }
        });

        if (!teamsToCascade) {
          this.teams = t;
          this.teamIds = t.map(t1 => t1._id);
        } else {
          this.teamsToCascade = t;
          this.teamIdsToCascade = t.map(t1 => t1._id);
        }
      });
  }
}
