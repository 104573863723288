import { OneSchemaTemplateKey } from '@ninety/data-import/models/one-schema-template-key';
import { TerraIconName } from '@ninety/terra';
import { DataImportItemType } from '@ninety/ui/legacy/shared/models/enums/data-import-item-type';
import { CustomLanguage } from '@ninety/ui/legacy/shared/models/language/custom-language';

export type DataImportType = {
  importType: DataImportItemType;
  templateKey: OneSchemaTemplateKey;
  icon: TerraIconName;

  /** For hard coded labels */
  label?: string;

  /** For custom language labels */
  languageKey?: keyof CustomLanguage;
  labelKey?: string;
};

export const CUSTOMER_FACING_DATA_IMPORT_TYPES: Readonly<DataImportType[]> = [
  {
    label: 'Users',
    icon: 'directory',
    importType: DataImportItemType.user,
    templateKey: OneSchemaTemplateKey.users,
  },
];

export const HELPFUL_ONLY_DATA_IMPORT_TYPES: Readonly<DataImportType[]> = [
  {
    label: 'Users',
    icon: 'directory',
    importType: DataImportItemType.user,
    templateKey: OneSchemaTemplateKey.users,
  },
  {
    languageKey: 'todo',
    labelKey: 'items',
    icon: 'to-dos',
    importType: DataImportItemType.todo,
    templateKey: OneSchemaTemplateKey.todos,
  },
  {
    languageKey: 'issue',
    labelKey: 'items',
    icon: 'issues',
    importType: DataImportItemType.issue,
    templateKey: OneSchemaTemplateKey.issues,
  },
  {
    languageKey: 'rock',
    labelKey: 'items',
    icon: 'rocks',
    importType: DataImportItemType.rock,
    templateKey: OneSchemaTemplateKey.rocks,
  },
];
