import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ScorecardRow } from '@ninety/scorecard-v2/models/scorecard-row.model';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { Score } from '@ninety/ui/legacy/shared/models/scorecard-v2/score';

export const ScorecardPageActions = createActionGroup({
  source: 'Scorecard V2 - Page',
  events: {
    Init: emptyProps(),
    Destroy: emptyProps(),

    'Init Scorecard Id': props<{ scorecardId: string }>(),
    'Set Scorecard Id': props<{ scorecardId: string }>(),

    'Fetch By Team Id': props<{ teamId: string }>(),

    'Open Universal Create Dialog': props<{
      kpiGroupId: string;
      kpiId: string;
      itemType: Extract<ItemType, ItemType.issue | ItemType.todo>;
    }>(),

    'Open Universal Create Dialog Custom Goal': props<{
      score: Score;
      kpiId: string;
      columnLabel: string;
      itemType: ItemType.issue;
    }>(),

    'Open Kpi Future Goal Dialog': props<{ kpiGroupId: string; kpiId: string }>(),
    'Open Custom Goal Dialog': props<{
      score: Score;
      row: ScorecardRow;
      columnLabel: string;
      kpiGroupId: string;
      kpiId: string;
    }>(),
  },
});
