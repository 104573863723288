<div data-cy="user-profile-menu_menu-container" class="main" *ngrxLet="isHelpful$ as isHelpful">
  <terra-avatar
    class="avatar"
    size="xxl"
    [src]="user?.metadata?.picture?.url"
    [initials]="user | userInitials"
    [inactive]="user.active === false" />

  <div class="user-info">
    <div class="name">{{ user.metadata?.name | displayFullName }}</div>
    <div class="info">{{ user.role }}</div>
    <div class="email">{{ user.primaryEmail }}</div>
  </div>

  <hr />

  <div class="menu-group">
    <div
      data-product="main-sidebar__user-profile-user-settings"
      data-cy="user-profile-menu_user-settings"
      class="menu-item"
      [class.nav-disabled]="disabled"
      (click)="itemClicked('settings/user/profile')">
      <terra-icon icon="user-circle" />
      User Settings
      <terra-icon class="alert-icon" *ngIf="hasSettingsAlerts$ | async" icon="warning-circle" variant="fill" />
    </div>

    <ng-container *ngIf="user.isManageeOrAbove">
      <div
        data-product="main-sidebar__user-profile-notifications"
        data-cy="user-profile-menu_notifications"
        class="menu-item"
        [class.nav-disabled]="disabled"
        (click)="itemClicked('settings/user/notifications')">
        <terra-icon icon="bell" />
        Notifications
      </div>
    </ng-container>

    <div
      data-product="main-sidebar__user-profile-teams"
      *ngIf="user.isManager"
      class="menu-item"
      [class.nav-disabled]="disabled"
      (click)="itemClicked('settings/company/teams')">
      <terra-icon icon="users-three" />
      Teams
    </div>

    <ng-container *ngIf="stateService.isAdminOrOwner || user.isImplementer">
      <div
        data-product="main-sidebar__user-profile-company-settings"
        data-cy="user-profile-menu_company-settings"
        class="menu-item"
        [class.nav-disabled]="disabled"
        (click)="itemClicked('settings/company/configuration')">
        <terra-icon icon="users-three" />
        Company Settings
      </div>

      <div
        data-product="main-sidebar__user-profile-company-billing"
        data-cy="user-profile-menu_company-billing"
        class="menu-item"
        (click)="itemClicked('settings/billing')">
        <terra-icon icon="credit-card" />
        Billing
      </div>
    </ng-container>
  </div>
  <hr />

  <ng-container *ngIf="(hideReferFriendMenuItem$ | async) === false && !isHelpful">
    <div class="menu-group">
      <div
        data-product="main-sidebar__user-profile-company-refer-a-friend"
        class="menu-item"
        (click)="openReferralUrl()">
        <terra-icon icon="currency-dollar-circle" />
        Refer a friend
      </div>
    </div>
    <hr />
  </ng-container>

  <ng-container *ngIf="isHelpful">
    <div class="menu-group">
      <div data-product="main-sidebar__user-profile-helpful" class="menu-item" (click)="itemClicked('helpful')">
        <terra-icon icon="question-circle" />
        Helpful
      </div>

      <div
        data-product="main-sidebar__user-profile-partners"
        class="menu-item"
        *ngIf="hasPartnerPermission$ | ngrxPush"
        (click)="itemClicked('partners')">
        <terra-icon icon="partner-hub" />
        Partners
      </div>
    </div>
    <hr />
  </ng-container>

  <div class="menu-group">
    <div
      data-cy="user-profile-menu_logout"
      data-product="main-sidebar__user-profile-log-out"
      class="menu-item"
      (click)="logout()">
      <terra-icon icon="sign-out" />
      Log Out
    </div>
  </div>
</div>
