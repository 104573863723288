import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DummyComponent } from './components/dummy/dummy.component';
import { TerraFormGroupModule } from './components/forms/terra-form-group/terra-form-group.module';
import { TerraSearchInputModule } from './components/inputs/terra-search-input/terra-search-input.module';
import { TerraTextInputModule } from './components/inputs/terra-text-input/terra-text-input.module';
import { TerraAvatarModule } from './components/terra-avatar/terra-avatar.module';
import { TerraBadgeModule } from './components/terra-badge/terra-badge.module';
import { TerraCheckboxModule } from './components/terra-checkbox/terra-checkbox.module';
import { TerraCounterModule } from './components/terra-counter/terra-counter.module';
import { TerraDividerModule } from './components/terra-divider/terra-divider.module';
import { TerraFormFieldModule } from './components/terra-form-field/terra-form-field.module';
import { TerraIconModule } from './components/terra-icon/terra-icon.module';
import { TerraOptionModule } from './components/terra-option/terra-option.module';
import { TerraSelectModule } from './components/terra-select/terra-select.module';
import { TerraSwitchModule } from './components/terra-switch/terra-switch.module';
import { TerraBooleanAttributeModule } from './pipes/boolean-attribute/boolean-attribute.module';

@NgModule({
  imports: [
    CommonModule,
    TerraDividerModule,
    TerraCounterModule,
    TerraIconModule,
    TerraSwitchModule,
    TerraBooleanAttributeModule,
    TerraSelectModule,
    TerraCheckboxModule,
    TerraFormGroupModule,
    TerraOptionModule,
    TerraAvatarModule,
    TerraTextInputModule,
    TerraBadgeModule,
    TerraSearchInputModule,
    TerraFormFieldModule,
  ],
  declarations: [DummyComponent],
  exports: [
    TerraFormFieldModule,
    TerraSearchInputModule,
    TerraBadgeModule,
    TerraTextInputModule,
    TerraAvatarModule,
    TerraOptionModule,
    TerraCheckboxModule,
    TerraSelectModule,
    TerraFormGroupModule,
    TerraSwitchModule,
    TerraIconModule,
    TerraCounterModule,
    TerraDividerModule,
    DummyComponent,
    TerraBooleanAttributeModule,
  ],
})
export class TerraModule {}
