import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';

import { ButtonComponent } from '@ninety/ui/legacy/components/index';
import { MeetingLanguage } from '@ninety/ui/legacy/shared/index';

export enum DeleteOption {
  one = 'one',
  all = 'all',
}

export type DeleteDialogOptions = {
  deleteAll: boolean;
  isRepeating: boolean;
};

export type DeleteMeetingScheduleDialogData = {
  meetingLanguage: MeetingLanguage;
  isRepeating: boolean;
  cadence: string;
};

@Component({
  selector: 'ninety-delete-meeting-schedule-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatRadioModule, FormsModule, ButtonComponent],
  templateUrl: './delete-meeting-schedule-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteMeetingScheduleDialogComponent {
  DeleteOption = DeleteOption;
  deleteOption = DeleteOption.one;

  constructor(
    public dialogRef: MatDialogRef<DeleteMeetingScheduleDialogComponent, DeleteDialogOptions>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteMeetingScheduleDialogData
  ) {}

  onDelete(): void {
    this.dialogRef.close({ deleteAll: this.deleteOption === DeleteOption.all, isRepeating: this.data.isRepeating });
  }
}
