import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap, tap } from 'rxjs';

import { DataImportSelectors } from '@ninety/data-import/_state/data-import-state.selectors';
import { DataImportActions } from '@ninety/data-import/_state/data-import.actions';
import { OneSchemaTemplateKey } from '@ninety/data-import/models/one-schema-template-key';
import { IssueRecordsData, RockRecordsData, TodoRecordsData } from '@ninety/data-import/models/records';
import { IssueRecord } from '@ninety/data-import/models/records/issue-record';
import { RockRecord } from '@ninety/data-import/models/records/rock-record';
import { TodoRecord } from '@ninety/data-import/models/records/todo-record';
import { UserRecord } from '@ninety/data-import/models/records/user-record';
import { DataImportService } from '@ninety/data-import/services/data-import.service';
import { ConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/models';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { InviteUserPayload } from '@ninety/ui/legacy/shared/models/directory/invite-user-payload';
import { DataImportItemType } from '@ninety/ui/legacy/shared/models/enums/data-import-item-type';
import { TeamSelectors } from '@ninety/ui/legacy/state/app-entities/team-list/team-list-state.selectors';
import { selectAllUsers } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { BillingStateActions } from '@ninety/ui/legacy/state/app-global/billing/billing-state.actions';
import { selectCompanyBillingCounts } from '@ninety/ui/legacy/state/app-global/billing/billing-state.selectors';
import { selectCompany } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import { NotificationActions } from '@ninety/ui/legacy/state/app-global/notifications/notification.actions';
import { SpinnerActions } from '@ninety/ui/legacy/state/app-global/spinner/spinner-state.actions';

@Injectable()
export class DataImportStateEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private dataImportService: DataImportService,
    private dialog: MatDialog
  ) {}

  initSelectedTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.init),
      concatLatestFrom(() => this.store.select(TeamSelectors.selectFilterBarTeamId)),
      map(([, teamId]) => DataImportActions.selectTeam({ teamId }))
    )
  );

  initOneSchema$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.init),
      switchMap(() =>
        this.dataImportService.getOneSchemaToken().pipe(
          map(data => DataImportActions.launchOneSchema({ userJwt: data.token })),
          catchError((error: unknown) =>
            of(
              NotificationActions.notifyV2({
                message: `Failed to get token ${error}`,
                params: { config: { duration: 3000 } },
              })
            )
          )
        )
      )
    )
  );

  launchOneSchema$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataImportActions.launchOneSchema),
        concatLatestFrom(() => this.store.select(DataImportSelectors.selectTemplateKey)),
        tap(([{ userJwt }, templateKey]) => {
          this.dataImportService.launchOneSchema(templateKey, userJwt);
        })
      ),
    { dispatch: false }
  );

  setTemplate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataImportActions.selectTemplate, DataImportActions.success),
        concatLatestFrom(() => [
          this.store.select(DataImportSelectors.selectToken),
          this.store.select(DataImportSelectors.selectTemplateKey),
        ]),
        tap(([_, userJwt, templateKey]) => this.dataImportService.launchOneSchema(templateKey, userJwt))
      ),
    { dispatch: false }
  );

  destroy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataImportActions.destroy),
        tap(() => this.dataImportService.destroy())
      ),
    { dispatch: false }
  );

  /** ======== Issues  ======== */
  handleIssuesFromImport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.handleImportData),
      filter(({ data }) => data.template_key === OneSchemaTemplateKey.issues),
      concatLatestFrom(() => this.store.select(DataImportSelectors.selectTeamId)),
      map(([{ data }, teamId]: [IssueRecordsData, string]) => {
        const issues = IssueRecord.toIssues(data.records, teamId);
        return DataImportActions.createIssues({ issues });
      })
    )
  );

  createIssues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.createIssues),
      switchMap(({ issues }) =>
        this.dataImportService.createIssues(issues).pipe(
          map(() => DataImportActions.success({ key: 'issue' })),
          catchError((error: unknown) => of(DataImportActions.error({ error })))
        )
      )
    )
  );

  /** ======== /Issues  ======== */

  /** ======== Rocks  ======== */
  handleRocksFromImport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.handleImportData),
      filter(({ data }) => data.template_key === OneSchemaTemplateKey.rocks),
      concatLatestFrom(() => [
        this.store.select(DataImportSelectors.selectTeamId),
        this.store.select(TeamSelectors.selectAll),
      ]),
      map(([{ data }, teamId, teams]: [RockRecordsData, string, Team[]]) => {
        const rocks = RockRecord.toRocks(data.records, teamId, teams);
        return DataImportActions.createRocks({ rocks });
      })
    )
  );

  createRocks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.createRocks),
      switchMap(({ rocks }) =>
        this.dataImportService.createRocks(rocks).pipe(
          map(() => DataImportActions.success({ key: 'rock' })),
          catchError((error: unknown) => of(DataImportActions.error({ error })))
        )
      )
    )
  );

  /** ======== /Rocks  ======== */

  /** ======== Todos  ======== */
  handleTodosFromImport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.handleImportData),
      filter(({ data }) => data.template_key === OneSchemaTemplateKey.todos),
      concatLatestFrom(() => this.store.select(DataImportSelectors.selectTeamId)),
      map(([{ data }, teamId]: [TodoRecordsData, string]) => {
        const todos = TodoRecord.toTodos(data.records, teamId);
        return DataImportActions.createTodos({ todos });
      })
    )
  );

  createTodos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.createTodos),
      switchMap(({ todos }) =>
        this.dataImportService.createTodos(todos).pipe(
          map(() => DataImportActions.success({ key: 'todo' })),
          catchError((error: unknown) => of(DataImportActions.error({ error })))
        )
      )
    )
  );
  /** ======== /Todos  ======== */

  /** ======== Users  ======== */
  getBillingCountsWhenImportingUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.selectTemplate),
      /** if we implement deep linking to a particular template, we need to make sure this fires */
      filter(({ option }) => option.importType === DataImportItemType.user),
      map(() => BillingStateActions.getCompanyBillingCounts())
    )
  );

  handleUsersFromImport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.handleImportData),
      filter(({ data }) => data.template_key === OneSchemaTemplateKey.users),
      concatLatestFrom(() => [
        this.store.select(selectAllUsers),
        this.store.select(selectCompany),
        this.store.select(TeamSelectors.selectAll),
      ]),
      map(([{ data }, currentUsers, company, allTeams]) => {
        const usersToImport: InviteUserPayload[] = UserRecord.toInviteUsersPayload(data.records, company, allTeams);
        const currentUserEmails: string[] = currentUsers.map(u => u.emailAddresses[0]?.email);

        const alreadyAddedEmails = usersToImport.filter(u => currentUserEmails.includes(u.email)).map(u => u?.email);

        if (!alreadyAddedEmails.length) return DataImportActions.checkNumberOfLicenses({ users: usersToImport });

        return NotificationActions.notifyV2({
          message: `Users already found in company with the following email addresses: ${alreadyAddedEmails.join(
            ', '
          )}`,
          params: { config: { duration: 10000 } },
        });
      })
    )
  );

  checkNumberOfLicenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.checkNumberOfLicenses),
      concatLatestFrom(() => this.store.select(selectCompanyBillingCounts)),
      map(([{ users }, billingCounts]) => {
        if (users.length <= billingCounts.assignableSeats) return DataImportActions.createUsers({ users });
        return DataImportActions.openLicenseChangeConfirmDialog({ users, billingCounts });
      })
    )
  );

  openLicenseChangeConfirmDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.openLicenseChangeConfirmDialog),
      switchMap(({ users, billingCounts }) => {
        const seatOrSeats = billingCounts.assignableSeats > 1 ? 'seats' : 'seat';
        const availableSeatSentence = billingCounts.assignableSeats
          ? `, but you only have <strong>${billingCounts.assignableSeats} ${seatOrSeats} available</strong>.`
          : '.';

        return this.dialog
          .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
            data: {
              title: 'License change required',
              message: `You are about to import <strong>${users.length} users</strong>${availableSeatSentence}
You will be charged for an additional <strong>${users.length - billingCounts.assignableSeats} seats</strong>.`,
              confirmButtonText: 'Confirm ',
            },
          })
          .afterClosed()
          .pipe(
            filter(result => result),
            map(() => DataImportActions.createUsers({ users }))
          );
      })
    )
  );

  createUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.createUsers),
      switchMap(({ users }) =>
        this.dataImportService
          .createUsers(users)
          .pipe(catchError((error: unknown) => of(DataImportActions.error({ error }))))
      ),
      map(() => DataImportActions.success({ message: `Successfully imported users` }))
    )
  );

  startSpinnerWhenCreatingUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.createUsers),
      map(() => SpinnerActions.startPrimary({ source: 'Data Import' }))
    )
  );
  /** ========  /Users  ======== */

  downloadCSVTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.downloadCSVTemplate),
      concatLatestFrom(() => this.store.select(DataImportSelectors.selectTemplateKey)),
      map(([_, templateKey]) => {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', `https://ninety-oneschema-data-import-templates.s3.amazonaws.com/${templateKey}.csv`);
        link.setAttribute('download', `template.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        const toast = {
          message: `The template has been saved to your download folder.`,
          title: 'Template downloaded!',
          override: { timeOut: 10000 },
        };
        return NotificationActions.success(toast);
      })
    )
  );

  success$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.success),
      concatLatestFrom(() => this.store.select(selectLanguage)),
      map(([{ key, message }, language]) => {
        const successMessage = message || `Successfully imported ${language[key].items}`;
        return NotificationActions.notifyV2({ message: successMessage, params: { config: { duration: 3000 } } });
      })
    )
  );

  startSpinnerSpinnerOnAPIRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.handleImportData, DataImportActions.init),
      concatLatestFrom(() => this.store.select(DataImportSelectors.selectTemplateKey)),
      filter(([_, templateKey]) => templateKey !== OneSchemaTemplateKey.users),
      map(() => SpinnerActions.startPrimary({ source: 'Data Import' }))
    )
  );

  stopSpinnerSpinnerOnAPIResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.success, DataImportActions.error, DataImportActions.launchOneSchema),
      map(() => SpinnerActions.stopPrimary({ source: 'Data Import' }))
    )
  );
}
