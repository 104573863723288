// TODO: Work with data team to normalize event names
export enum SegmentTrackEvent {
  ADDED_PERSON_TO_DIRECTORY = 'Added Person To Directory',
  AGREEMENT_BASED_TODOS_CHANGED = 'AB Todos Changed',
  CASCADED_CREATED = 'Cascaded Message Created',
  CASCADED_SET_COMPLETED = 'Cascaded Message Set Completed',
  GETTING_STARTED_GUIDE_FORCED_OPEN = 'Getting Started Guide Forced Open',
  HEADLINE_CREATED = 'Headline Created',
  HEADLINE_SET_COMPLETED = 'Headline Set Completed',
  INS_CONVERSATION_TYPE_CHANGE = 'Insights Conversation Type Change',
  INS_PERIOD_CHANGE = 'Insights Period Change',
  INS_LAC_COMMITMENT_TYPE_CHANGE = 'Insights LAC Commitment Type Change',
  INS_MANAGER_CHANGE = 'Insights Selected Manager Change',
  INVITED_USER = 'Invited A User',
  ISSUE_CREATED = 'Issue Created',
  ISSUE_SHORT_TERM_SET_COMPLETED = 'Issue Short Term Set Completed',
  KPI_CREATED = 'KPI Created',
  KPI_GROUP_ADDED_KPIS = 'Added KPIs to KPI Group',
  KPI_GROUP_CREATED = 'KPI Group Created',
  KPI_SCORE_CHANGED = 'KPI Score Changed',
  LINKED_ITEM_CREATED = 'Linked Item Created',
  LINKED_ITEM_OPENED = 'Linked Item Opened',
  LOGIN = 'Logged In',
  MAC_CLONED = 'MAC Cloned',
  MAC_CREATED = 'MAC Created',
  MAC_SET_PUBLIC_CHART = 'MAC Set Public Chart',
  /** @deprecated */
  MEASURABLE_CREATED = 'Measurable Created',
  MEETING_CREATE = 'Meeting Created',
  ORG_ATTACHMENT_EVENT = 'ORG Attachment Event',
  ORG_CREATE_SEAT = 'ORG Create Seat',
  ORG_LAUNCH_SEAT_DETAILS = 'ORG Launch Seat Details',
  ORG_MANAGER_CHANGED = 'ORG Manager Changed',
  ORG_UPDATE_SEAT = 'ORG Update Seat',
  PAYMENT_ADDED = 'Payment Method Added',
  REGISTER_COMPANY = 'Company Registered',
  REGISTER_COMPANY_APPLY_COUPON_FAILED = 'register-company-auto-discount-failed',
  REGISTER_COMPANY_FAIL = 'Company Registration Failed',
  ROCK_CREATED = 'Rock Created',
  ROCK_STATUS_UPDATED = 'Rock Status Updated',
  SUBSCRIPTION_CANCELLED = 'Subscription Cancelled',
  THEME_CHANGED = 'Theme Changed',
  TODO_CREATED = 'Todo Created',
  TODO_SET_COMPLETED = 'Todo Set Completed',
  TODOS_TABLE_CONTEXT_MENU = 'Todos Table Opened Context Menu',
  TRIAL_STARTED = 'Trial Started',
  UPDATED_STRIPE_PAYMENT_METHOD = 'Updated Stripe Payment Method',
  USER_EVENT_NOTIFICATION_CHANGED = 'Event Notification Settings Changed',
  GOOGLE_TASK_INTEGRATION_CHANGED = 'Google Task Integration Changed',
  MICROSOFT_TASK_INTEGRATION_CHANGED = 'Microsoft Task Integration Changed',

  ERROR = 'Error',
}
